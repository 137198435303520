/* eslint-disable no-fallthrough */

const INITAL_STATE = {
  rooms: [],
}

export default function roomsReducer(state = INITAL_STATE, action) {
  switch (action.type) {
    case "ADD_ROOM": {
      return { ...state, rooms: [...state.rooms, action.payload] }
    }
    case "ADD_RUIMTE_DATA": {
      let val = { ...state }
      val.rooms[action.index]["facade"] = action.data.facade
      val.rooms[action.index]["spaceMeasures"] = action.data.spaceMeasures
      val.rooms[action.index]["spaceUse"] = action.data.spaceUse
      val.rooms[action.index].stepsDone.first = true
      return { ...val }
    }
    case "ADD_ORIENTATION_DATA": {
      let val = { ...state }
      val.rooms[action.index]["space"] = action.data.space
      val.rooms[action.index]["orientation"] = action.data.orientation
      val.rooms[action.index].stepsDone.second = true
      return { ...val }
    }
    case "ADD_INSTALATION_DATA": {
      let val = { ...state }
      val.rooms[action.index]["instalationCapacity"] =
        action.data.instalationCapacity
      val.rooms[action.index]["climateConcept"] = action.data.climateConcept
      val.rooms[action.index].stepsDone.third = true
      return { ...val }
    }
    case "DELETE_ROOM": {
      return { ...state, rooms: action.rooms }
    }
    case "CHANGE_ROOM_NAME": {
      let val = { ...state }
      val.rooms[action.index].name = action.name
      return { ...val }
    }
    case "ADD_HEATING_CLASS": {
      let val = { ...state }
      val.rooms[action.roomIndex].heatingClasses[action.heatingIndex] =
        action.className
      return { ...val }
    }
    case "ADD_COOLING_CLASS": {
      let val = { ...state }
      val.rooms[action.roomIndex].coolingClasses[action.heatingIndex] =
        action.className
      return { ...val }
    }
    case "ADD_CALCULATED_CLASSES": {
      let val = { ...state }
      val.rooms[action.roomIndex]["calculatedClasses"] = action.classes
      return { ...val }
    }
    case "ADD_AIRFLOW_HEATLOAD_DATA": {
      let val = { ...state }
      val.rooms[action.roomIndex].airFlowHeatLoadData[action.dataKey] =
        action.dataValue
      return { ...val }
    }
    case "TOGGLE_DONE_STEPS": {
      let val = { ...state }
      val.rooms[action.index].stepsDone[action.step] = action.value
    }
    case "SET_VALUE_TOO_HIGH": {
      let val = { ...state }
      val.rooms[action.index]["valueTooHigh"] = action.value
      return { ...val }
    }
    default: {
      return { ...state }
    }
  }
}
