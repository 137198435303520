/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, Redirect } from "react-router"

import MediumHeadline from "components/MediumHeadline"
import Button from "components/Button"
import DeleteModal from "components/DeleteModal"
import AddRoom from "components/AddRoomModal"
import Input from "components/Input"
import {
  deleteRoom,
  changeActiveRoom,
  changeRoomName,
  addRoom,
} from "redux/actions"

import XIcon from "assets/images/x-icon.svg"
import PlusIcon from "assets/images/plus-icon.svg"
import Pencil from "assets/images/pencil.svg"
import { BsCheckLg } from "react-icons/bs"
import { IoCloseSharp } from "react-icons/io5"
import ArrowRight from "assets/images/arrow-right.svg"

const DeleteButton = ({ onClick, text }) => {
  return (
    <div
      className={`flex items-center justify-center py-3 px-4 cursor-pointer bg-buttonGray`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <img src={XIcon} alt="icon" className="h-4 w-4" />
        <p className="text-white">{text}</p>
      </div>
    </div>
  )
}

const ButtonNext = ({ onClick, text }) => {
  return (
    <div
      className={`flex items-center justify-center py-2 px-4 w-64 cursor-pointer bg-lightBlue`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <img src={ArrowRight} alt="icon" className="h-4 w-4" />
        <p className="text-white ml-3">{text}</p>
      </div>
    </div>
  )
}

const Rooms = () => {
  const [openModal, setOpenModal] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [roomName, setRoomName] = useState("")
  const [stateRooms, setStateRooms] = useState([])
  const [addRoomModal, setAddRoomModal] = useState(false)
  const [newRoomName, setNewRoomName] = useState("")
  const [redirect, setRedirect] = useState(null)
  const [invalidRoom, setInvalidRoom] = useState(false)

  const history = useHistory()
  const rooms = useSelector((state) => state.rooms.rooms)
  const projectDetails = useSelector((state) => state.data)
  const dispatch = useDispatch()

  const removeRoom = () => {
    let roomsCopy = [...rooms]
    roomsCopy.splice(itemToDelete, 1)
    dispatch(deleteRoom(roomsCopy))
    setStateRooms(roomsCopy)
    setOpenModal(false)
  }

  const setEdit = (index) => {
    let room = [...stateRooms]
    room.forEach((elem) => (elem.edit = false))
    room.forEach((elem, i) => {
      if (index === i) {
        setRoomName(elem.name)
        elem.edit = true
      }
    })
    setStateRooms([...room])
  }

  const closeEdit = () => {
    let room = [...stateRooms]
    room.forEach((elem) => (elem.edit = false))
    setStateRooms([...room])
    setInvalidRoom(false)
  }

  const changeName = (index) => {
    if (roomName === "") return setInvalidRoom(true)
    dispatch(changeRoomName(roomName, index))
    closeEdit()
    setRoomName("")
  }

  const addNewRoom = () => {
    if (newRoomName === "") return setInvalidRoom(true)
    dispatch(addRoom(newRoomName))
    setNewRoomName("")
    setAddRoomModal(false)
    setTimeout(() => {
      dispatch(changeActiveRoom(newRoomName, stateRooms.length))
      history.push({
        pathname: `/binnenklimaat/room-step/1/${stateRooms.length}`,
      })
    }, 100)
  }

  useEffect(() => {
    if (projectDetails.Email === "")
      return setRedirect(<Redirect to="/wizard/projectgegevens" />)

    let room = [...rooms]
    room.forEach((elem) => {
      elem["edit"] = false
    })
    setStateRooms([...room])
  }, [rooms])

  return (
    <div className="laptop:pr-40 xl:pr-96 pt-10">
      {redirect}
      <DeleteModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
        onConfirm={removeRoom}
      />
      <AddRoom
        open={addRoomModal}
        value={newRoomName}
        invalid={invalidRoom}
        onChange={(e) => {
          setNewRoomName(e.target.value)
          setInvalidRoom(false)
        }}
        onConfirm={() => addNewRoom()}
        closeModal={() => {
          setAddRoomModal(false)
          setInvalidRoom(false)
        }}
      />
      <MediumHeadline text="Ruimtes" />
      <p className="text-h5 md:text-base pt-3">
        Maak hier de referentie ruimtes van het kantoorgebouw aan. Dit kunnen
        zowel ruimtes met een kantoorfunctie als een bijeenkomstfunctie zijn.
        Per ruimte zullen vervolgens de afmetingen, bezetting, interne
        warmtebelasting, gevelopbouw, gevel oriëntatie, ventilatiedebiet en de
        lokale klimaatinstallatie worden ingevoerd.
      </p>
      <div className="mt-8">
        <div className="flex">
          <p className="w-9/12 text-lightBlue text-12">Naam</p>
        </div>
        <div>
          {stateRooms.length > 0 &&
            stateRooms.map((elem, index) =>
              !elem.edit ? (
                <div
                  className="flex py-2 border-b border-borderColor"
                  key={index}
                >
                  <p className="w-9/12 text-darkBlue font-bold text-h3 flex items-center cursor-pointer">
                    <span
                      onClick={() => {
                        dispatch(changeActiveRoom(elem.name, index))
                        history.push({
                          pathname: `/binnenklimaat/room-step/1/${index}`,
                        })
                      }}
                    >
                      {elem.name}{" "}
                    </span>
                    <img
                      src={Pencil}
                      alt="pencil"
                      className="ml-2 cursor-pointer"
                      onClick={() => setEdit(index)}
                    />
                  </p>
                  <div className="flex items-center space-x-2">
                    <ButtonNext
                      text="BEKIJK OF WIJZIG"
                      onClick={() => {
                        dispatch(changeActiveRoom(elem.name, index))
                        history.push({
                          pathname: `/binnenklimaat/room-step/1/${index}`,
                        })
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        setItemToDelete(index)
                        setOpenModal(true)
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex py-2 border-b border-borderColor"
                  key={index}
                >
                  <div className="w-9/12 text-darkBlue font-bold text-h3 flex items-center cursor-pointer">
                    <div className="w-2/4">
                      <Input
                        value={roomName}
                        invalid={invalidRoom}
                        onChange={(e) => {
                          setRoomName(e.target.value)
                          setInvalidRoom(false)
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-1 flex">
                    <div
                      className="flex items-center justify-center bg-green h-10 w-10 cursor-pointer"
                      onClick={() => changeName(index)}
                    >
                      <BsCheckLg className="text-white" />
                    </div>
                    <div
                      className="flex items-center justify-center bg-red h-10 w-10 ml-5 cursor-pointer"
                      onClick={() => closeEdit(index)}
                    >
                      <IoCloseSharp className="text-white" />
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
        <div className="mt-8">
          <Button
            isDark
            text="VOEG EEN RUIMTE TOE"
            icon={PlusIcon}
            onClick={() => {
              setAddRoomModal(true)
              // dispatch(changeActiveRoom('', null))
              // history.push('/wizard/room-step')
            }}
          />
        </div>
      </div>
      <div className="flex">
        <p
          className="bottom-0 right-0 absolute mr-8 mb-4 text-gray text-xs"
          style={{ fontSize: "12px" }}
        >
          Deze tool is ontwikkeld door{" "}
          <a
            href="https://renor.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline-none"
          >
            Renor
          </a>{" "}
        </p>
      </div>
    </div>
  )
}

export default Rooms
