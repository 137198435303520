import React from "react"

const Classes = ({ className }) => {
  let style =
    "border-4 border-blackBorder w-10 h-10 md:w-12 md:h-12 rounded-lg flex justify-center items-center text-white font-bold text-h4 md:text-h3"
  let greenA = "bg-classA"
  let greenB = "bg-classB"
  let yellowC = "bg-classC"
  let redD = "bg-classD"

  switch (className) {
    case "A":
      return <div className={style + " " + greenA}>A</div>
    case "B":
      return <div className={style + " " + greenB}>B</div>
    case "C":
      return <div className={style + " " + yellowC}>C</div>
    case "D":
      return <div className={style + " " + redD}>D</div>
    default:
      return <p>No class</p>
  }
}

export default Classes
