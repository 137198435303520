import React from "react"
import Button from "components/Button"
import { useDispatch } from "react-redux"
import ArrowRight from "assets/images/arrow-right.svg"
import { useHistory } from "react-router"
import { changeActiveTool } from "redux/actions"
const posts = [
  {
    id: 1,
    title: "...comfortprestaties van mijn installatie controleren",
    buttonText: "Doe de Binnenklimaatcheck",
    href: "/",
    mainTitle: "Binnenklimaatcheck",
    subtitle: "Controleer het binnenklimaat van jouw ruimte",
    navigation: "/binnenklimaat/projectgegevens",
  },
  {
    id: 2,
    title: "...de energieprestatie van een nieuw gebouw bepalen",
    buttonText: "Doe de BENGcheck",
    mainTitle: "BENGcheck",
    subtitle: "Bereken snel indicatieve BENG scores",
    href: "/",
    navigation: "/BENGcheck/projectgegevens",
  },
  {
    id: 3,
    title: "...de energieprestatie van een bestaand gebouw bepalen",
    buttonText: "Doe de Energielabelcheck",
    mainTitle: "Energielabelcheck",
    subtitle: "Bereken snel een indicatief energielabel",
    href: "/",
    navigation: "/energielabelcheck/projectgegevens",
  },
  // More posts...
]
export default function InformationToolCard() {
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <div className="-mt-16">
      <div className="mx-auto">
        <div className="mx-auto grid grid-cols-1 gap-y-16 gap-x-8 pt-10 sm:mt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex max-w-xl flex-col items-start justify-between shadow-md rounded-xl place-items-center"
            >
              <div className="w-full text-center">
                <p className="pt-5 font-mulish leading-6 text-lightBlue font-bold text-center w-52 mx-auto">
                  {post.title}
                </p>
              </div>
              <div className="mt-5 mx-auto" style={{ width: "90%" }}>
                <Button
                  text={post.buttonText}
                  btnStyling={
                    "w-full flex items-center justify-center py-3 px-2 cursor-pointer mb-4"
                  }
                  textSize="text-h5"
                  isDark={false}
                  icon={ArrowRight}
                  iconStyle={"h-4 w-4 mr-0"}
                  onClick={() => {
                    dispatch(
                      changeActiveTool(
                        post.mainTitle,
                        post.navigation,
                        post.subtitle,
                        post?.id
                      )
                    )
                    history.push(post.navigation)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
