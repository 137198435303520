import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import MediumHeadline from "components/MediumHeadline"
import ErrorAlert from "components/ErrorAlert"
import Stepper from "components/Stepper"
import AlgemeenSection from "components/AlgemeenSection"
import GebouwSection from "components/BENGGebouwSection"
import InstallatiesSection from "components/BENGInstallatiesSection"
import { changeActiveTool } from "redux/actions"
import ProjectService from "services/project.service"
import { firebaseAuth } from "firebase-config"
import { fillData, addProjectToFirebase } from "redux/actions"
// import Zeercompact from "../assets/images/zeercompact.png"
// import Redeljik from "../assets/images/redeljik.png"
// import Gemiddeld from "../assets/images/gemiddeld.png"
// import Minder from "../assets/images/minder.png"
// import Niet from "../assets/images/niet.png"
// import ArrowRight from "../assets/images/arrow-right.svg"
// import Button from "components/Button"
// import HorizontalStepper from "components/GebouwSection/HorizontalStepper"
// import CompactheidCard from "components/CompactheidCard"

// const stepitems = [
//   {
//     id: 0,
//     value: "30%",
//     secondaryValue: "Rk Gaven = 2.5",
//   },
//   {
//     id: 1,
//     value: "40%",
//     secondaryValue: "Rk Gaven = 2.5",
//   },
//   {
//     id: 2,
//     value: "50%",
//     secondaryValue: "Rk Gaven = 2.5",
//   },
//   {
//     id: 3,
//     value: "60%",
//     secondaryValue: "Rk Gaven = 2.5",
//   },
//   {
//     id: 4,
//     value: "70%",
//     secondaryValue: "Rk Gaven = 2.5",
//   },
// ]

// const PLACEHOLDER_DATA = [
//   {
//     id: 1,
//     title: "Zeer compact",
//     subtitle: "Avl / Ag = 0,8",
//     image: Zeercompact,
//   },
//   {
//     id: 2,
//     title: "Zeer compact",
//     subtitle: "Avl / Ag = 1,0",
//     image: Redeljik,
//   },
//   {
//     id: 3,
//     title: "Zeer compact",
//     subtitle: "Avl / Ag = 1,2",
//     image: Gemiddeld,
//   },
//   {
//     id: 4,
//     title: "Zeer compact",
//     subtitle: "Avl / Ag = 1,4",
//     image: Minder,
//   },
//   {
//     id: 5,
//     title: "Zeer compact",
//     subtitle: "Avl / Ag = 1,6",
//     image: Niet,
//   },
// ]

const Projectgegevens = ({ setPage }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [email, setEmail] = useState(true)
  const [gebruiksoppervlakte, setGebruiksoppervlakte] = useState(true)

  const stepHandler = (step) => {
    setCurrentStep(step)
  }

  const setEmailError = () => {
    setEmail(false)
    setGebruiksoppervlakte(false)
    return false
  }
  const data = useSelector((state) => state.data)
  const [customErrorText, setCustomErrorText] = useState(null)

  const checkForEmail = () => {
    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (data.Email === "" || !regEmail.test(data.Email)) {
      setCustomErrorText("Deze informatie is verplicht.")
      return setEmailError()
    } else if (
      data.Gebruiksoppervlakte === "" ||
      parseInt(data.Gebruiksoppervlakte) < 500
    ) {
      setCustomErrorText("Het minimale gebruiksoppervlakte bedraagt 500 m2")
      return setEmailError()
    } else return addProject()
  }

  const dispatch = useDispatch()
  const stateData = useSelector((state) => state.data)

  const [isCalculated, setIsCalculated] = useState(false)
  const [isError, setIsError] = useState(false)

  const onChangeStep = (step) => {
    if (step === 1) {
      setCurrentStep(step)
      dispatch(fillData({ ...stateData, currPage: step }))
    } else if (step === 2) {
      if (checkForEmail()) {
        setIsError(false)
        setIsCalculated(true)
        setCurrentStep(step)
        dispatch(fillData({ ...stateData, currPage: step }))
      } else {
        setIsError(true)
        return
      }
    } else if (isCalculated && step === 3) {
      setCurrentStep(step)
      dispatch(fillData({ ...stateData, currPage: step }))
    }
  }

  const addProject = async () => {
    try {
      if (data.projectAddedToFirebase) return true
      firebaseAuth
        .signInAnonymously()
        .then(() => {
          const response = new ProjectService()
            .create({
              createdAt: new Date(),
              email: data.Email,
              createdPDF: false,
              name: "BENGcheck",
              toolId: 2,
            })
            .then((res) => {
              dispatch(addProjectToFirebase(res.id))
            })
            .catch((err) => {
              console.error(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  useEffect(() => {
    dispatch(
      changeActiveTool(
        "BENGcheck",
        "/",
        "Bereken snel indicatieve BENG scores",
        2
      )
    )
  }, [])

  return (
    <div id="flowBodySection" className="relative laptop:pr-40 xl:pr-96 pt-9">
      {currentStep === 1 ? (
        <MediumHeadline
          text="Algemeen"
          subtext="Op deze pagina vul je de algemene gegevens in voor het gebouw. Het vloeroppervlak kan worden gebruikt in de berekening, de overige gegevens worden getoond bij de resultaten."
        />
      ) : currentStep === 2 ? (
        <MediumHeadline
          text="Gebouw"
          subtext="Vul hieronder de gegevens in van de thermische schil van het gebouw. Deze gegevens bepalen de score voor BENG 1 en worden later ook gebruikt voor de overige scores."
        />
      ) : currentStep === 3 ? (
        <MediumHeadline
          text="Installaties"
          subtext="Vul hieronder de gegevens in van de installaties in het gebouw. Deze gegevens bepalen samen met de gegevens van de thermische schil de scores voor: BENG 1, BENG 2, BENG 3 en het energielabel. "
        />
      ) : (
        <MediumHeadline
          text="Algemeen"
          subtext="Op deze pagina vul je de algemene gegevens in voor het gebouw. Het vloeroppervlak kan worden gebruikt in de berekening, de overige gegevens worden getoond bij de resultaten."
        />
      )}

      {/* <p className="text-h5 md:text-base pt-5 mb-2">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p> */}
      <Stepper step={currentStep} onChangeStep={onChangeStep} />

      <ErrorAlert
        open={isError}
        setIsError={setIsError}
        errorText={customErrorText}
      />

      {currentStep === 1 ? (
        <AlgemeenSection
          stepHandler={stepHandler}
          email={email}
          setEmail={setEmail}
          gebruiksoppervlakte={gebruiksoppervlakte}
          setGebruiksoppervlakte={setGebruiksoppervlakte}
          onChangeStep={onChangeStep}
        />
      ) : currentStep === 2 ? (
        <GebouwSection onChangeStep={onChangeStep} />
      ) : currentStep === 3 ? (
        <InstallatiesSection />
      ) : (
        <AlgemeenSection
          stepHandler={stepHandler}
          email={email}
          setEmail={setEmail}
          gebruiksoppervlakte={gebruiksoppervlakte}
          setGebruiksoppervlakte={setGebruiksoppervlakte}
          onChangeStep={onChangeStep}
        />
      )}
      <div className="relative">
        <p
          className="bottom-0 right-0 absolute ml-20 mb-4 text-gray text-xs"
          style={{ fontSize: "12px" }}
        >
          Deze tool is ontwikkeld door{" "}
          <a
            href="https://renor.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline-none"
          >
            Renor
          </a>{" "}
        </p>
      </div>
    </div>
  )
}

export default Projectgegevens
