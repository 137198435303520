/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { changeActiveRoom, fillData } from "redux/actions"

import { PDFDownloadLink } from "@react-pdf/renderer"
import { Redirect } from "react-router"
import InfoModal from "components/InfoModal"
import moment from "moment"
// import { ExclamationIcon } from "@heroicons/react/solid"

import {
  calculcateLuchtdebiet,
  calculateInterneWarmtelastSum,
} from "calculationFunctions/calculateAirflowHeatLoad"
import { powerAvailable } from "calculationFunctions/powerAvailable"
import { calculateClasses } from "calculationFunctions/calculateClasses"
import { addCalculatedClasses } from "redux/actions"

import MediumHeadline from "components/MediumHeadline"
import Button from "components/Button"
import PDFGenerator from "components/PDFGenerator"
import LoadingSpinner from "components/LoadingSpinner"

import PDFIcon from "assets/images/pdf-icon.svg"
import { airFlowHeatLoad } from "constants/airFlowHeatLoad"
import InfoIcon from "assets/images/info.png"

import ProjectService from "services/project.service"
import { firebaseAuth } from "firebase-config"
import { CalculateClimateResults } from "calculationFunctions/calculateClimateResults"

// const DownloadPDFText = ({ text }) => {
//   return (
//     <div className="rounded-md bg-yw-50 p-4 w-full text-white text-left flex place-items-center">
//       <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
//       <p className="ml-2 text-yellow-700">
//         Download het PDF rapport indien u de resultaten wilt bewaren
//       </p>
//     </div>
//   )
// }

// const klimaatconcept = [
//   { name: "Klimaatplafonds", value: 8 },
//   { name: "Plafondinductie-units", value: 2.7 },
//   { name: "VAV + naverwarmer", value: 10 },
//   { name: "Fancoil-units", value: 4 },
//   { name: "Vloerverwarming/-koeling", value: 8 },
//   { name: "Radiatoren + topkoeling", value: 8 },
// ];

// const spanClass = "absolute right-12 top-0 text-8 text-greyWarning";
// const winterAsterix = "absolute right-10 font-normal text-8 text-greyWarning";
// const zomerAsterix = "absolute right-9 font-normal text-8 text-greyWarning";

const Results = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalText, setInfoModalText] = useState("")
  const [infoModalTitle, setInfoModalTitle] = useState("")

  const projectDetails = useSelector((state) => state.data)

  const results = useSelector((state) => state.rooms.rooms)
  const activeToolReduxObject = useSelector((state) => state.activeTool)
  const dispatch = useDispatch()

  const [canGeneratePDF, setCanGeneratePDF] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [redirect, setRedirect] = useState(null)

  const openMailer = () => {
    window.open(
      "mailto:info@tvvl.nl?subject=Contactaanvraag klimaatinstallatiecheck.nl&body="
    )
  }

  const { Beng1, Beng2, Beng3, Energielabel } = CalculateClimateResults()
  const PDFResults = { Beng1, Beng2, Beng3, Energielabel }

  const calculateRooms = (result, roomIndex) => {
    airFlowHeatLoad.ventilatiehoeveelheidPerson =
      result.airFlowHeatLoadData.ventilatiehoeveelheidPerson
    calculcateLuchtdebiet(
      result.airFlowHeatLoadData.ventilatiehoeveelheid,
      result.airFlowHeatLoadData.totaalVloeroppervlak
    )
    calculateInterneWarmtelastSum(
      result.airFlowHeatLoadData.interneWarmtelast,
      result.airFlowHeatLoadData.bezzeting,
      result.airFlowHeatLoadData.totaalVloeroppervlak
    )
    let power = powerAvailable(result.climateConcept)

    let classes = calculateClasses(
      {
        heatingClasses: result.heatingClasses.join(""),
        coolingClasses: result.coolingClasses.join(""),
      },
      power
    )
    dispatch(addCalculatedClasses(roomIndex, classes))
    setIsLoading(false)
  }

  const checkForSteps = () => {
    if (results.length === 0) return setIsLoading(false)

    for (let i = 0; i < results.length; i++) {
      if (
        !results[i].stepsDone.first ||
        !results[i].stepsDone.second ||
        !results[i].stepsDone.third
      ) {
        setCanGeneratePDF(false)
        setIsLoading(false)
      } else {
        calculateRooms(results[i], i)
      }
    }
  }

  const updateFirebase = async () => {
    try {
      firebaseAuth
        .signInAnonymously()
        .then(() => {
          new ProjectService().update(projectDetails.projectId, {
            createdPDF: true,
          })
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  useEffect(() => {
    if (projectDetails.Email === "")
      return setRedirect(<Redirect to="/wizard/projectgegevens" />)
    checkForSteps()

    const handleBackButton = () => {
      // Your function to be triggered when the back button is pressed
      dispatch(fillData({ ...projectDetails, currPage: 1 }))
      dispatch(changeActiveRoom("Gegevens"))
    }
    window.addEventListener("popstate", handleBackButton)
    return () => {
      window.removeEventListener("popstate", handleBackButton)
    }
  }, [])

  return isLoading ? (
    <div className="h-full w-full flex items-center justify-center mt-44">
      <LoadingSpinner />
      {redirect}
    </div>
  ) : (
    <div className="mt-10">
      <InfoModal
        open={modalOpen}
        text={infoModalText}
        title={infoModalTitle}
        image={null}
        closeModal={() => setModalOpen(false)}
      />
      <MediumHeadline
        text={`${
          projectDetails.Projectnaam
            ? "Resultaten: " + projectDetails.Projectnaam
            : "Resultaten"
        }`}
        subtext="Op deze pagina vind je de resultaten van de indicative berekening. Wil je de resultaten bewaren? Vergeet dan niet om het rapport (pdf) te downloaden."
      />
      <div className="lg:flex  gap-5 mt-5">
        <div className="w-full">
          {!canGeneratePDF ? (
            <div
              className="bg-red-100 border-red-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md mt-5 w-auto inline-block"
              role="alert"
            >
              <div className="flex px-1 md:w-4/5">
                <div className="py-1">
                  <svg
                    className="fill-current h-6 w-6 text-red-500 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold">
                    Sorry, we kunnen geen PDF genereren.
                  </p>
                  <p className="text-sm">
                    Er ontbreken nog gegevens op één of meerdere ruimtes
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <PDFDownloadLink
              document={
                <PDFGenerator
                  results={PDFResults}
                  details={projectDetails}
                  activeTool={activeToolReduxObject}
                />
              }
              fileName={`${activeToolReduxObject?.activeTool}${
                projectDetails.Projectnaam
                  ? "_" + projectDetails.Projectnaam
                  : ""
              }_${moment().format("DDMMYYYY")}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <div className="mt-5">
                    <Button isDark text="LOADING" icon={PDFIcon} />
                  </div>
                ) : (
                  <div className="w-full">
                    <Button
                      isDark
                      pdfDownloadButton
                      onClick={updateFirebase}
                      text="DOWNLOAD EEN PDF RAPPORT VAN DE RESULTATEN"
                      icon={PDFIcon}
                      btnStyling="pl-4 w-auto flex justify-start py-3 px-2 cursor-pointer"
                    />
                  </div>
                )
              }
            </PDFDownloadLink>
          )}
        </div>
        <div className="bg-blueGrayOpacity rounded-lg p-5 w-1/3">
          <p className="font-bold text-darkBlue mb-2 mt-1">
            Heb je een vraag of suggestie?
          </p>
          <p className="text-darkBlue text-h5"></p>
          <p className=" text-h6 md:text-base">
            Schroom niet en neem contact met ons op. Stuur een email naar:{" "}
            <span
              className="font-bold text-lightBlue cursor-pointer"
              onClick={openMailer}
            >
              info@tvvl.nl
            </span>
          </p>
        </div>
      </div>

      <div className="mt-16 mb-10 border-4 border-blueGrayOpacity p-5 overflow-auto w-300 sm:w-360 md:w-500 lg:w-full">
        <MediumHeadline text={projectDetails.Gebouwnaam} />
        <div className="mt-5 grid grid-cols-4 gap-16 mr-2 lg:mr-20">
          <div>
            <div className="flex flex-col bg-darkBlue py-1 px-2">
              <p className="text-white text-sm p-1">BENG 1</p>
            </div>
            <div className="flex flex-row">
              <p className="text-h3 text-darkBlue ml-2 mt-4 font-bold">
                {Math.round(Beng1)}
              </p>
              <p className="text-h5 ml-2 mt-7">kWh/m²</p>
              <img
                src={InfoIcon}
                className="cursor-pointer h-5 mt-7 ml-2"
                alt="info"
                onClick={() => {
                  setInfoModalText(
                    "BENG 1: de maximale energiebehoefte in kWh per m2 gebruiksoppervlak per jaar (kWh/m2.jr)."
                  )
                  setInfoModalTitle("BENG 1")
                  setModalOpen(true)
                }}
              />
            </div>
          </div>
          <div>
            <div className="bg-darkBlue py-1 px-2">
              <p className="text-white text-sm p-1">BENG 2</p>
            </div>
            <div className="flex flex-row">
              <p className="text-h3 text-darkBlue ml-2 mt-4 font-bold">
                {Math.round(Beng2)}
              </p>
              <p className="text-h5 ml-2 mt-7">kWh/m²</p>
              <img
                src={InfoIcon}
                className="cursor-pointer h-5 mt-7 ml-2"
                alt="info"
                onClick={() => {
                  setInfoModalText(
                    "BENG 2: het maximale primair fossiel energiegebruik in kWh per m2 gebruiksoppervlak per jaar (kWh/m2.jr)."
                  )
                  setInfoModalTitle("BENG 2")
                  setModalOpen(true)
                }}
              />
            </div>
          </div>
          <div>
            <div className="bg-darkBlue py-1 px-2">
              <p className="text-white text-sm p-1">BENG 3</p>
            </div>
            <div className="flex flex-row">
              <p className="text-h3 text-darkBlue ml-2 mt-4 font-bold">
                {Math.round(Beng3)}
              </p>
              <p className="text-h5 ml-2 mt-7">%</p>
              <img
                src={InfoIcon}
                className="cursor-pointer h-5 mt-7 ml-2"
                alt="info"
                onClick={() => {
                  setInfoModalText(
                    "BENG 3: het minimale aandeel hernieuwbare energie in procenten (%)."
                  )
                  setInfoModalTitle("BENG 3")
                  setModalOpen(true)
                }}
              />
            </div>
          </div>
          <div>
            <div className="bg-lightBlue py-1 px-2 ">
              <p className="text-white text-sm p-1">Energielabel</p>
            </div>
            <div className="flex flex-row">
              <div
                className="mt-2 py-1 px-3 text-white text-h3 pr-6"
                style={{
                  backgroundColor: Energielabel.color,
                  clipPath: "polygon(75% 1%, 100% 50%, 75% 100%, 0 100%, 0 0)",
                }}
              >
                {Energielabel.name}
              </div>
              <img
                src={InfoIcon}
                className="cursor-pointer h-5 mt-7 ml-2"
                alt="info"
                onClick={() => {
                  setInfoModalText(
                    "Energielabel: de energieprestatieklasse van het gebouw, gebaseerd op het maximaal primair fossiel energieverbruik (BENG 2)."
                  )
                  setInfoModalTitle("Energielabel")
                  setModalOpen(true)
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="text-darkBlue font-bold font-mulish text-h4">
            Disclaimer:
          </h3>
          <p className="font-mulish text-h5 mt-3">
            De resultaten in deze tool zijn indicatief en zijn derhalve niet
            geschikt om energieprestatieberekeningen van kantoorgebouwen te
            vervangen. Om te kunnen vaststellen of werkelijk aan de wettelijke
            kaders voor energieprestatie wordt voldaan, dient een volledige
            berekening volgens de NTA 8800 te worden uitgevoerd.{" "}
          </p>
        </div>
      </div>
      <div className="relative">
        <p
          className="bottom-0 right-0 absolute ml-20 my-4 text-gray text-xs"
          style={{ fontSize: "12px" }}
        >
          Deze tool is ontwikkeld door{" "}
          <a
            href="https://renor.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline-none"
          >
            Renor
          </a>{" "}
        </p>
      </div>
    </div>
  )
}

export default Results
