import { database } from "../firebase-config"

class ProjectService {
  constructor() {
    this.db = database.collection("project")
  }

  getAll() {
    return this.db
  }

  create(data) {
    return this.db.add(data)
  }

  update(id, data) {
    return this.db.doc(id).update(data)
  }
}

export default ProjectService
