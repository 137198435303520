import React from "react"
import "./index.css"

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div
        className="
            loader
            ease-linear
            rounded-full
            border-8 border-t-8 border-blackBorder
            h-32
            w-32
          "
      ></div>
    </div>
  )
}

export default LoadingSpinner
