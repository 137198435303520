import React from "react"

const GreyHeadline = ({ text }) => {
  return (
    <div className="font-bold text-gray font-mulish md:text-12 laptop:text-12">
      {text.toUpperCase()}
    </div>
  )
}

export default GreyHeadline
