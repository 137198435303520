import React from "react"

const KlimatConceptExplanation = ({ concept }) => {
  switch (concept) {
    case "Klimaatplafonds":
      return (
        <div>
          <p>
            Bij een klimaatplafond wordt de complete klimaatinstallatie
            geïntegreerd in het plafond. Verse lucht vanuit de
            luchtbehandelingskast wordt via plafondroosters het vertrek
            ingeblazen. De gebruikte lucht wordt via roosters of openingen in
            het plafond afgezogen door de afzuiginstallatie.
          </p>
          <p className="mt-5">
            Zowel verwarming als koeling vinden plaats middels een watervoerend
            pakket dat aan de bovenzijde van het plafond wordt aangebracht. De
            warmte en koude wordt hierbij via het metalen plafond aan de ruimte
            overgedragen. De ruimtetemperatuur kan het hele jaar op ruimteniveau
            worden geregeld middels de ruimtebedienunit van de regelinstallatie,
            waarbij het mogelijk is om snel en nauwkeurig te regelen.
          </p>
        </div>
      )
    case "Plafondinductie-units":
      return (
        <div>
          <p>
            Bij een klimaatinstallatie met plafondinductieunits wordt verse
            lucht vanuit de luchtbehandelingskast via de plafondinductieunits
            het vertrek ingeblazen. Als gevolg van de injectie van de
            ventilatielucht door de nozzles in de inductieunits, vindt er
            natuurlijk luchtcirculatie (inductie) in de ruimte plaats. De
            gebruikte lucht wordt via roosters of openingen in het plafond
            afgezogen door de afzuiginstallatie.
          </p>
          <p className="mt-5">
            Zowel verwarming als koeling vinden plaats middels een verwarmings-
            en een koelbatterijen in de plafondinductieunits. De
            ruimtetemperatuur kan het hele jaar op ruimteniveau worden geregeld
            middels de ruimtebedienunit van de regelinstallatie, waarbij het
            mogelijk is om snel en nauwkeurig te regelen.
          </p>
        </div>
      )
    case "VAV + naverwarmer":
      return (
        <div>
          <p>
            Bij een klimaatinstallatie met een VAV-systeem kan de hoeveelheid
            ventilatielucht per vertrek worden afgestemd op de vraag vanuit de
            ruimte. Verse lucht vanuit de luchtbehandelingskast wordt via
            plafondroosters het vertrek ingeblazen, waarbij de hoeveelheid
            ventilatielucht wordt geregeld door een VAV-box. De gebruikte lucht
            wordt via roosters of openingen in het plafond afgezogen door de
            afzuiginstallatie.
          </p>
          <p className="mt-5">
            Verwarming van het vertrek vindt bij deze installatie plaats middels
            naverwarmers in het inblaaskanaal. Koeling vindt zomers plaats
            middels de koelbatterij in de centrale luchtbehandelingskast,
            waarbij de hoeveelheid inblaaslucht per ruimte wordt gevarieerd
            middels de VAV-box. De ruimtetemperatuur kan het hele jaar op
            ruimteniveau worden geregeld middels de ruimtebedienunit van de
            regelinstallatie, waarbij het mogelijk is om snel en nauwkeurig te
            regelen.
          </p>
        </div>
      )
    case "Fancoil-units":
      return (
        <div>
          <p>
            Bij een klimaatinstallatie met fancoilunits wordt een klimaatunit
            boven het plafond aangebracht, waarmee de ruimtelucht mechanisch
            wordt gecirculeerd. Hierbij kan de toevoer van verse lucht vanuit de
            luchtbehandelingskast op de fancoilunit worden aangesloten. De
            fancoilunit blaast de geconditioneerde lucht in via plafondroosters.
            De aanzuig van ruimtelucht vindt plaats door een afzuigrooster in
            het plafond. De gebruikte lucht wordt boven het plafond afgezogen
            door de afzuiginstallatie.
          </p>
          <p className="mt-5">
            Zowel verwarming als koeling vinden plaats middels een verwarmings-
            en een koelbatterij in de fancoilunit. De ruimtetemperatuur kan het
            hele jaar op ruimteniveau worden geregeld middels de
            ruimtebedienunit van de regelinstallatie, waarbij het mogelijk is om
            snel en nauwkeurig te regelen.
          </p>
        </div>
      )
    case "Vloerverwarming/-koeling":
      return (
        <div>
          <p>
            Bij een klimaatinstallatie met vloerverwarming / vloerkoeling wordt
            een watervoerend pakket in de dekvloer aangebracht. Verse lucht
            vanuit de luchtbehandelingskast wordt via plafondroosters het
            vertrek ingeblazen. De gebruikte lucht wordt via roosters of
            openingen in het plafond afgezogen door de afzuiginstallatie.
          </p>
          <p className="mt-5">
            Zowel verwarming als koeling vinden plaats vanuit de vloer, waarbij
            de warmte en koude via de vloerafwerking aan de ruimte worden
            overgedragen. De ruimtetemperatuur kan het hele jaar op ruimteniveau
            worden geregeld middels de ruimtebedienunit van de regelinstallatie,
            waarbij het niet mogelijk is om snel te regelen.
          </p>
        </div>
      )
    case "Radiatoren + topkoeling":
      return (
        <div>
          <p>
            Bij een klimaatinstallatie met radiatoren en topkoeling wordt er
            verse lucht vanuit de luchtbehandelingskast wordt alleen voor de
            ventilatie van de ruimte gebruikt en wordt via plafondroosters het
            vertrek ingeblazen. De gebruikte lucht wordt via roosters of
            openingen in het plafond afgezogen door de afzuiginstallatie.
          </p>
          <p className="mt-5">
            {" "}
            Verwarming vindt plaats middels radiatoren aan de gevel. Koeling is
            slechts in beperkt maten aanwezig, doordat de ventilatielucht in de
            luchtbehandelingskast wordt ‘voorgekoeld’. De ruimtetemperatuur kan
            alleen in de wintersituatie worden geregeld middels de
            thermostaatkranen op de radiatoren, waarbij het niet mogelijk is om
            nauwkeurig te regelen.
          </p>
        </div>
      )
    default: {
      return
    }
  }
}

export default KlimatConceptExplanation
