import React, { useState, useEffect, createRef } from "react"

import { Switch, Route, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import Sidebar from "components/OriginalSidebar"
import WizardHeader from "components/WizardHeader"
import Container from "components/Container"

/* PAGES */
import Projectgegevens from "pages/OriginalProjectgegevens"
import Rooms from "./Rooms"
import Results from "./OriginalResults"
import RoomSteps from "./RoomSteps"

const Wizard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const error = useSelector((state) => state)
  const route = useLocation()
  const container = createRef()

  useEffect(() => {
    container.current.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [route.pathname])

  return (
    <main className="flex flex-col h-screen relative font-mulish">
      <div className="hidden lg:block">
        <WizardHeader />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <Sidebar
          open={sidebarOpen}
          closeSidebar={() => setSidebarOpen(false)}
        />
        <div className="flex flex-1 flex-col relative">
          <div
            className={`flex flex-col flex-1 bg-blue-300 overflow-y-auto`}
            ref={container}
          >
            <div className="lg:hidden">
              {" "}
              <WizardHeader openSidebar={() => setSidebarOpen(true)} />
            </div>
            {error.error.showError && (
              <div className="absolute right-0 w-64 border border-red p-5 bg-red rounded-lg text-white">
                {error.error.message}
              </div>
            )}
            <div className="laptop:pr-20 xl:pr-20">
              <Container>
                <Switch>
                  <Route
                    path="/binnenklimaat/projectgegevens"
                    component={Projectgegevens}
                  />
                  <Route path="/binnenklimaat/ruimtes" component={Rooms} />
                  <Route
                    path="/binnenklimaat/binnenklimaat-resultaten"
                    component={Results}
                  />
                  <Route
                    path="/binnenklimaat/room-step/:step/:room"
                    component={RoomSteps}
                  />
                  <Route path="*" component={Projectgegevens} />
                </Switch>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Wizard
