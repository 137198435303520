import React from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"

import Container from "components/Container"
import { GiHamburgerMenu } from "react-icons/gi"

import TVVLLogo from "assets/images/tvvl-logo.svg"
import binnenklimaattechiek from "assets/images/binnenklimaattechiek.svg"
import PlatformDuurzameHuisvesting from "assets/images/PlatformDuurzameHuisvesting-Logo.png"

const WizardHeader = ({ openSidebar }) => {
  const history = useHistory()
  const activeToolReduxObject = useSelector((state) => state.activeTool)

  function styleMainHeaderTitle(str) {
    if (!str || str.length <= 5) return

    const lastFiveChars = str.slice(-5)
    const otherChars = str.slice(0, -5)

    return (
      <span className="font-bold font-mulish text-darkBlue text-h2 cursor-pointer">
        <span>{otherChars}</span>
        <span className="text-lightBlue">{lastFiveChars}</span>
      </span>
    )
  }

  return (
    <div className="border-b-2 border-borderColor">
      {/* SMALL HEADER */}
      <Container>
        <div className="w-full z-10 relative py-5 lg:hidden flex items-center justify-between">
          <div className="w-3/5">
            <p
              className="font-bold font-mulish text-darkBlue text-h4 md:text-h2 cursor-pointer"
              onClick={() => {
                history.push("/")
                window.location.reload(false)
              }}
            >
              <span>{activeToolReduxObject?.activeTool?.slice(0, -5)}</span>
            </p>
          </div>
          <div onClick={openSidebar}>
            <GiHamburgerMenu className="md:w-8 md:h-8" />
          </div>
        </div>

        {/* BIG HEADER */}
        <div className="hidden lg:block md:py-5 xl:py-10 lg:flex place-items-center justify-between">
          <div>
            <p
              className="font-bold font-mulish text-darkBlue text-h2 md:text-h1 lg:text-h3 laptop:text-24 cursor-pointer"
              onClick={() => {
                history.push("/")
                window.location.reload(false)
              }}
            >
              {styleMainHeaderTitle(activeToolReduxObject?.activeTool)}
            </p>
            <p className="md:text-h3 lg:text-12">
              {activeToolReduxObject.subtitle}
            </p>
          </div>
          <div className="flex items-center">
            <img
              src={binnenklimaattechiek}
              alt="logo"
              className="w-64 mr-10 cursor-pointer"
            />
            <img
              src={TVVLLogo}
              alt="logo"
              className="w-32 cursor-pointer pb-1"
            />
            <img
              src={PlatformDuurzameHuisvesting}
              alt="logo"
              className="ml-2 w-20 cursor-pointer"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WizardHeader
