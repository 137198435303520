const INITAL_STATE = {
  showError: false,
  message: "",
}

export default function toggleError(state = INITAL_STATE, action) {
  switch (action.type) {
    case "TOGGLE_ERROR": {
      return {
        ...state,
        showError: action.data.showError,
        message: action.data.message,
      }
    }
    default: {
      return { ...state }
    }
  }
}
