import React from "react"
import { fillData } from "redux/actions"
import { useDispatch, useSelector } from "react-redux"

const activeStepStyles = {
  backgroundColor: "#009FDF",
  width: 10,
  height: 35,
}

const nonActiveStepStyles = {
  backgroundColor: "black",
  width: 5,
  height: 15,
  // marginRight: 10
}

const disabledStepStyles = {
  backgroundColor: "lightgray",
  width: 5,
  height: 15,
  // marginRight: 10
}
const disabledContentStyles = {
  opacity: 0.5,
}

const contentStyles = {
  fontWeight: "bold",
  fontSize: 14,
  color: "#009FDF",
  maxWidth: 120,
  marginTop: 50,
  textAlign: "center",
}

const StepItem = ({ item, selectedStep, setSelectedStep, topic }) => {
  const data = useSelector((state) => state.data)
  const dispatch = useDispatch()

  const onValueChange = (item) => {
    // console.log("item", item?.disabled !== undefined)
    if (item?.disabled === undefined) {
      setSelectedStep(item.id)
      dispatch(fillData({ ...data, [topic]: item }))
    }
  }

  return (
    <>
      <div
        key={item.id}
        className="flex flex-col items-center justify-center cursor-pointer px-1"
        onClick={() => onValueChange(item)}
      >
        <div className="flex mb-0 absolute">
          <div
            className="rounded"
            style={
              item?.disabled === undefined
                ? selectedStep === item.id
                  ? { ...activeStepStyles }
                  : { ...nonActiveStepStyles }
                : { ...disabledStepStyles }
            }
          />
        </div>

        {/* text underneath the stepper bar */}
        <div
          className="absolute mt-10"
          style={item?.disabled ? { ...disabledContentStyles } : {}}
        >
          <h1
            style={
              item?.tertiaryValue === undefined
                ? { ...contentStyles }
                : { ...contentStyles, marginTop: 70 }
            }
          >
            {item.value}
            {topic === "Aanvoer-temperatuur CV" && "°C"}
          </h1>
          {item?.secondaryValue && (
            <h1
              className=""
              style={{
                ...contentStyles,
                fontSize: 14,
                color: "#000",
                marginTop: 0,
                fontWeight: "400",
              }}
            >
              {item.secondaryValue}
            </h1>
          )}
          {item?.tertiaryValue && (
            <h1
              className="font-bold"
              style={{
                ...contentStyles,
                fontSize: 14,
                color: "#000",
                marginTop: 0,
                fontWeight: "400",
              }}
            >
              {item.tertiaryValue}
            </h1>
          )}
        </div>
      </div>
    </>
  )
}

export default StepItem
