import React from "react"

import ReactSelect, { components } from "react-select"
import Tooltip from "components/Tooltip"

import InfoIcon from "assets/images/info.png"
import ArrowDown from "assets/images/arrow-down.svg"

const Select = ({
  placeholder,
  options,
  label,
  value,
  onChange,
  info,
  invalid,
  infoText,
}) => {
  const selectStyle = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      height: "38px",
      fontSize: "10pt",
      border: state.isFocused
        ? "1px solid #707070"
        : invalid
        ? "1px solid #AF4860"
        : "1px solid #70707030",
      boxShadow: state.isFocused ? 0 : 0,
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "13.3px",
      color: "#B4B4B4",
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img className="mr-1 text-black" src={ArrowDown} alt="arrow" />
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <div className="h-20 w-full relative">
      <label className="text-lightBlue pb-1 text-sm flex">{label}</label>
      {info && (
        <img
          src={InfoIcon}
          data-tip={infoText}
          className="cursor-pointer absolute -top-2 right-0 h-5"
          alt="info"
        />
      )}
      <Tooltip />

      <ReactSelect
        isSearchable={false}
        options={options}
        styles={selectStyle}
        onChange={onChange}
        placeholder={placeholder}
        // defaultInputValue={value}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        defaultValue={value}
      />
    </div>
  )
}

export default Select
