/* eslint-disable no-unused-vars */
import React from "react"
import { useLocation, useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { changeActiveRoom } from "redux/actions"
import "./index.css"

import Container from "components/Container"
import GreyHeadline from "components/GreyHeadline"
import { AiOutlineClose } from "react-icons/ai"

import NavArrow from "assets/images/nav-arrow.svg"
import NavArrowBlue from "assets/images/nav-arrow-blue.svg"

const SidebarItem = ({ text, path, onClick, active }) => (
  <div className={`flex items-center cursor-pointer py-2`} onClick={onClick}>
    <img
      src={path === text && active === "" ? NavArrowBlue : NavArrow}
      alt="Arrow"
      className="mr-2"
    />
    <p
      className={`font-mulish font-bold text-darkBlue md:text-12 laptop:text-12 ${
        path === text && active === "" ? "text-lightBlue" : "text-darkBlue"
      }`}
    >
      {text}
    </p>
  </div>
)

const RoomItem = ({ text, path, onClick, active }) => (
  <div
    className={`flex items-center cursor-pointer py-2 ml-5`}
    onClick={onClick}
  >
    <img src={active ? NavArrowBlue : NavArrow} alt="Arrow" className="mr-2" />
    <p
      className={`font-mulish font-bold md:text-12 laptop:text-12 ${
        active ? "text-lightBlue" : "text-subitem"
      }`}
    >
      {text}
    </p>
  </div>
)

const Sidebar = ({ open, closeSidebar }) => {
  const location = useLocation()
  const history = useHistory()

  const dispatch = useDispatch()
  const rooms = useSelector((state) => state.rooms.rooms)
  const activeRoom = useSelector((state) => state.activeRoom.activeRoom)
  const email = useSelector((state) => state.data.Email)

  let path
  if (location.pathname === "/wizard") {
    path = "Projectgegevens"
  } else {
    path =
      location.pathname.split("/")[2].charAt(0).toUpperCase() +
      location.pathname.split("/")[2].slice(1)
  }

  return (
    <div>
      <div className="hidden lg:block">
        {/* BIG SCREEN SIDEBAR */}
        <div className="h-full lg:w-full pt-10">
          <Container>
            <div className="relative w-full">
              <GreyHeadline text="NAVIGATIE" />
              <div className="mt-5">
                <SidebarItem
                  text="Projectgegevens"
                  path={path}
                  onClick={() => {
                    history.push("/binnenklimaat/originalProjectgegevens")
                    dispatch(changeActiveRoom(""))
                  }}
                  active={activeRoom}
                />
                <SidebarItem
                  text="Ruimtes"
                  path={path}
                  onClick={() => {
                    if (email === "") return
                    history.push("/binnenklimaat/ruimtes")
                    dispatch(changeActiveRoom(""))
                  }}
                  active={activeRoom}
                />

                {rooms.map((elem, index) => (
                  <RoomItem
                    text={elem.name}
                    key={index}
                    active={elem.name === activeRoom}
                    onClick={() => {
                      if (email === "") return
                      dispatch(changeActiveRoom(elem.name, index))
                      setTimeout(() => {
                        history.push({
                          pathname: `/binnenklimaat/room-step/1/${index}`,
                        })
                      }, 100)
                    }}
                  />
                ))}

                <SidebarItem
                  text="Resultaten"
                  path={path}
                  onClick={() => {
                    if (email === "") return
                    history.push("/binnenklimaat/binnenklimaat-resultaten")
                    dispatch(changeActiveRoom(""))
                  }}
                  active={activeRoom}
                />
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden">
        {/* SMALL SCREEN SIDEBAR */}
        <div
          className={`absolute h-full bg-lightBlue z-20 laptop:w-1/5 ${
            open ? "openSidebar" : "closeSidebar"
          }`}
          style={{ background: "#ffffff" }}
        >
          <Container>
            <div className="relative w-full md:pt-20">
              <div
                className="absolute top-1 md:top-20 right-5"
                onClick={closeSidebar}
              >
                <AiOutlineClose color="black" className="md:h-8 md:w-8" />
              </div>
              <GreyHeadline text="NAVIGATIE" />
              <div className="mt-5">
                <SidebarItem
                  text="Projectgegevens"
                  path={path}
                  onClick={() => {
                    history.push("/binnenklimaat/originalProjectgegevens")
                    dispatch(changeActiveRoom(""))
                    closeSidebar()
                  }}
                  active={activeRoom}
                />
                <SidebarItem
                  text="Ruimtes"
                  path={path}
                  onClick={() => {
                    if (email === "") return
                    history.push("/binnenklimaat/ruimtes")
                    dispatch(changeActiveRoom(""))
                    closeSidebar()
                  }}
                  active={activeRoom}
                />
                {rooms.map((elem, index) => (
                  <RoomItem
                    text={elem.name}
                    key={index}
                    active={elem.name === activeRoom}
                    onClick={() => {
                      if (email === "") return
                      dispatch(changeActiveRoom(elem.name, index))
                      history.push({
                        pathname: `/binnenklimaat/room-step/1/${index}`,
                      })
                      closeSidebar()
                    }}
                  />
                ))}
                <SidebarItem
                  text="Resultaten"
                  path={path}
                  onClick={() => {
                    if (email === "") return
                    history.push("/binnenklimaat/binnenklimaat-resultaten")
                    dispatch(changeActiveRoom(""))
                    closeSidebar()
                  }}
                  active={activeRoom}
                />
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
