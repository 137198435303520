export const fillData = (data) => {
  return {
    type: "FILL_DATA",
    payload: data,
  }
}

export const addProjectToFirebase = (id) => {
  return {
    type: "ADD_TO_FIREBASE",
    payload: id,
  }
}

export const addRoom = (name) => {
  return {
    type: "ADD_ROOM",
    payload: {
      name: name,
      stepsDone: {
        first: false,
        second: false,
        third: false,
      },
      heatingClasses: ["", "", "", ""],
      coolingClasses: ["", "", "", "", "", ""],
      airFlowHeatLoadData: {
        totaalVloeroppervlak: 0,
        ventilatiehoeveelheid: 0,
        interneWarmtelast: 0,
        bezzeting: 0,
        ventilatiehoeveelheidPerson: 0,
      },
    },
  }
}

export const changeRoomName = (name, index) => {
  return {
    type: "CHANGE_ROOM_NAME",
    name,
    index,
  }
}

export const addRuimteData = (data, index) => {
  return {
    type: "ADD_RUIMTE_DATA",
    data,
    index,
  }
}

export const addOrientationData = (data, index) => {
  return {
    type: "ADD_ORIENTATION_DATA",
    data,
    index,
  }
}

export const addInstalationData = (data, index) => {
  return {
    type: "ADD_INSTALATION_DATA",
    data,
    index,
  }
}

export const deleteRoom = (rooms) => {
  return {
    type: "DELETE_ROOM",
    rooms,
  }
}

export const changeActiveRoom = (roomName, index) => {
  return {
    type: "CHANGE_ACTIVE_ROOM",
    roomName,
    index,
  }
}

export const changeActiveTool = (toolName, toolNavigaiton, toolSubtitle) => {
  return {
    type: "CHANGE_ACTIVE_TOOL",
    toolName,
    toolNavigaiton,
    toolSubtitle,
  }
}

export const addHeatingClass = (heatingIndex, className, roomIndex) => {
  return {
    type: "ADD_HEATING_CLASS",
    heatingIndex,
    className,
    roomIndex,
  }
}

export const addCoolingClass = (heatingIndex, className, roomIndex) => {
  return {
    type: "ADD_COOLING_CLASS",
    heatingIndex,
    className,
    roomIndex,
  }
}

export const addCalculatedClasses = (roomIndex, classes) => {
  return {
    type: "ADD_CALCULATED_CLASSES",
    classes,
    roomIndex,
  }
}

export const addAirFlowHeadLoadData = (roomIndex, dataKey, dataValue) => {
  return {
    type: "ADD_AIRFLOW_HEATLOAD_DATA",
    dataKey,
    roomIndex,
    dataValue,
  }
}

export const toggleError = (data) => {
  return {
    type: "TOGGLE_ERROR",
    data,
  }
}

export const toggleIsCalculationDone = (data) => {
  return {
    type: "TOGGLE_CALCULATOIN_DONE",
    data,
  }
}

export const toggleDoneSteps = (roomIndex, step, value) => {
  return {
    type: "TOGGLE_DONE_STEPS",
    index: roomIndex,
    step,
    value,
  }
}
export const valueTooHigh = (roomIndex, value) => {
  return {
    type: "SET_VALUE_TOO_HIGH",
    index: roomIndex,
    value,
  }
}
