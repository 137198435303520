/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import InfoModal from "components/InfoModal"
import { fillData } from "redux/actions"
import { changeActiveRoom, toggleIsCalculationDone } from "redux/actions"
import ConfirmModal from "components/ConfirmModal"
import "./index.css"

import Container from "components/Container"
import GreyHeadline from "components/GreyHeadline"
import {
  AiOutlineClose,
  AiOutlineReload,
  AiOutlineExclamationCircle,
} from "react-icons/ai"

import NavArrow from "assets/images/nav-arrow.svg"
import NavArrowBlue from "assets/images/nav-arrow-blue.svg"

import InfoIcon from "assets/images/info.png"
import { CalculateClimateResults } from "calculationFunctions/calculateClimateResults"

const SidebarItem = ({ text, path, onClick, active }) => (
  <div className={`flex items-center cursor-pointer py-2`} onClick={onClick}>
    <img
      src={path === text && active === "" ? NavArrowBlue : NavArrow}
      alt="Arrow"
      className="mr-2"
    />
    <p
      className={`font-mulish font-bold md:text-12 laptop:text-12 ${
        active ? "text-lightBlue" : "text-darkBlue"
      }`}
    >
      {text}
    </p>
  </div>
)

const RoomItem = ({ text, path, onClick, active }) => (
  <div
    className={`flex items-center cursor-pointer py-2 ml-5`}
    onClick={onClick}
  >
    <img src={active ? NavArrowBlue : NavArrow} alt="Arrow" className="mr-2" />
    <p
      className={`font-mulish font-bold md:text-12 laptop:text-12 ${
        active ? "text-lightBlue" : "text-subitem"
      }`}
    >
      {text}
    </p>
  </div>
)

const ResetButton = ({ text, onClick }) => (
  <div className="flex items-center cursor-pointer p-1" onClick={onClick}>
    <AiOutlineReload color="black" className="min-h-4 min-w-4 h-4 w-4 mr-2" />
    <p className="font-mulish md:text-12 laptop:text-12 text-subitem">{text}</p>
  </div>
)

const AboutTheToolButton = ({ text, onClick }) => (
  <div className="flex items-center cursor-pointer p-1" onClick={onClick}>
    <AiOutlineExclamationCircle
      color="black"
      className="min-h-4 min-w-4 h-4 w-4 mr-2"
    />
    <p className="font-mulish md:text-12 laptop:text-12 text-subitem">{text}</p>
  </div>
)

const Sidebar = ({ open, closeSidebar }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [infoModalOpen, setInfoModalOpen] = React.useState(false)
  const [infoModalText, setInfoModalText] = useState("")
  const [infoModalTitle, setInfoModalTitle] = useState("")
  const activeToolReduxObject = useSelector((state) => state.activeTool)

  const currentRoom = useSelector((state) => state.activeRoom.activeRoom)
  const activeRoom = currentRoom === "" ? "Gegevens" : currentRoom

  const [activeItem, setActiveItem] = useState(activeRoom)
  const location = useLocation()
  const history = useHistory()
  const projectDetails = useSelector((state) => state.data)

  const dispatch = useDispatch()
  const rooms = useSelector((state) => state.rooms.rooms)

  const email = useSelector((state) => state.data.Email)

  const currentPage = projectDetails.currPage

  const { Beng1, Beng2, Beng3, Energielabel } = CalculateClimateResults()

  const stateData = useSelector((state) => state.data)
  const { isCalculationDone } = useSelector((state) => state.isCalculationDone)

  // console.log("active room: ", activeRoom)
  // console.log("current page: ", currentPage)
  // console.log("state data: ", stateData)

  const modalTitle = "Alle informatie wordt nu verwijderd, weet je dit zeker?"

  let path
  if (location.pathname === "/wizard") {
    path = "Projectgegevens"
  } else {
    path =
      location.pathname.split("/")[2].charAt(0).toUpperCase() +
      location.pathname.split("/")[2].slice(1)
  }

  //function for handling active item in sidebar
  const handleItemClick = (text) => {
    setActiveItem(text)
  }

  useEffect(() => {
    if (activeRoom === "Resultaten") {
      // setActiveItem("Gegevens")
      dispatch(changeActiveRoom("Gegevens"))
    }
  }, [])

  useEffect(() => {
    setActiveItem(activeRoom)
  }, [currentRoom, activeRoom])

  return (
    <div className="sm:w-0 lg:w-1/5">
      <InfoModal
        open={infoModalOpen}
        text={infoModalText}
        title={infoModalTitle}
        image={null}
        closeModal={() => setInfoModalOpen(false)}
      />
      <ConfirmModal
        open={modalOpen}
        title={modalTitle}
        onReset={() => {
          history.push("/")
          window.location.reload(false)
        }}
        onClose={() => setModalOpen(false)}
        closeModal={() => setModalOpen(false)}
      />
      <div className="hidden lg:block overflow-y-auto h-full pt-10">
        {/* BIG SCREEN SIDEBAR */}
        <div className="h-full">
          <Container>
            <div className="relative w-full">
              <GreyHeadline text="NAVIGATIE" />
              <div className="mt-5">
                <SidebarItem
                  text="Gegevens"
                  path={path}
                  onClick={() => {
                    activeToolReduxObject.activeTool === "BENGcheck"
                      ? history.push("/BENGcheck/projectgegevens")
                      : history.push("/energielabelcheck/projectgegevens")
                    dispatch(changeActiveRoom("Gegevens"))
                    dispatch(fillData({ ...stateData, currPage: 1 }))
                    handleItemClick("Gegevens")
                  }}
                  active={activeItem === "Gegevens"}
                />

                {rooms.map((elem, index) => (
                  <RoomItem
                    text={elem.name}
                    key={index}
                    active={elem.name === activeRoom}
                    onClick={() => {
                      if (email === "") return
                      dispatch(changeActiveRoom(elem.name, index))
                      setTimeout(() => {
                        history.push({
                          pathname: `/binnenklimaat/room-step/1/${index}`,
                        })
                      }, 100)
                    }}
                  />
                ))}

                <SidebarItem
                  text="Resultaten"
                  path={path}
                  onClick={() => {
                    if (
                      (currentPage === 1 && !isCalculationDone) ||
                      (currentPage === 2 && !isCalculationDone)
                    )
                      return
                    history.push("/energielabelcheck/resultaten")
                    dispatch(changeActiveRoom("Resultaten"))
                    dispatch(toggleIsCalculationDone(true))
                    handleItemClick("Resultaten")
                  }}
                  active={activeItem === "Resultaten"}
                />
              </div>
            </div>
            <div className="mt-5">
              <ResetButton
                text="Reset het formulier"
                onClick={() => setModalOpen(true)}
              />
              <AboutTheToolButton
                text="Over de tool"
                onClick={() => {
                  setInfoModalTitle("Over de tool")
                  setInfoModalText(
                    activeToolReduxObject.activeTool === "Energielabelcheck" ||
                      activeToolReduxObject.activeTool === "BENGcheck" ? (
                      <>
                        <p>
                          De tools BENGcheck en Energielabelcheck zijn
                          ontwikkeld om snel een indicatie te kunnen geven van
                          de energieprestatie van uw kantoorgebouw. Beide tools
                          werken op basis van een vereenvoudigd rekenmodel. Door
                          het aantal invoerparameters beperkt te houden, kost
                          het u slechts enkele minuten om een eerste indruk van
                          de energieprestatie te krijgen. Om te komen tot de
                          uiteindelijke set van invoerparameters (12 voor
                          nieuwbouw, 13 voor bestaande bouw) is een zorgvuldige
                          gevoeligheidsanalyse uitgevoerd. Voor parameters die
                          niet invulbaar zijn is een weloverwogen uitgangspunt
                          gekozen. De belangrijkste uitgangspunten voor het
                          model zijn hieronder benoemd.
                        </p>
                        <h4 className="mt-4 text-darkBlue font-bold">
                          Algemeen
                        </h4>
                        <ul className="list-disc ml-6">
                          <li className="list-item">
                            het gebouw kent een volledige kantoorfunctie;
                          </li>
                          <li className="list-item">
                            het klimaatjaar is De Bilt conform NTA 8800.
                          </li>
                        </ul>
                        <h4 className="mt-4 text-darkBlue font-bold">
                          Indeling van het gebouw
                        </h4>
                        <ul className="list-disc ml-6">
                          <li className="list-item">
                            het gebouw is vrijstaand, heeft 5 bouwlagen en een
                            totale hoogte van 18,0 m;
                          </li>
                          <li className="list-item">
                            de gebouwmassa bedraagt 500 tot 750 kg/m2;
                          </li>
                          <li className="list-item">
                            het type plafond is gesloten/verlaagd;
                          </li>
                          <li className="list-item">
                            het gebouw heeft 5 toiletgroepen met elektrische
                            boilers en uittapleiding ≤ 3,0m;
                          </li>
                          <li className="list-item">
                            de ramen zijn gelijkmatig over de gevels verdeeld;
                          </li>
                          <li className="list-item">
                            de ramen op de zonbelaste gevels zijn voorzien van
                            automatisch gestuurde buitenzonwering.
                          </li>
                        </ul>
                        <h4 className="mt-4 text-darkBlue font-bold">
                          Klimaatinstallatie
                        </h4>
                        <ul className="list-disc ml-6">
                          <li className="list-item">
                            er wordt geen zomernachtventilatie toegepast;
                          </li>
                          <li className="list-item">
                            er is balansventilatie, zonder recirculatie of
                            bypass aanwezig;
                          </li>
                          <li className="list-item">
                            de luchtbehandelingskast is buiten de thermische
                            zone opgesteld en voorzien van verwarming en
                            koeling;
                          </li>
                          <li className="list-item">
                            klimaatinstallaties zijn geïsoleerd en ingeregeld
                            (details onbekend);
                          </li>
                          <li className="list-item">
                            lokale klimaatinstallaties worden automatisch per
                            ruimte geregeld;
                          </li>
                          <li className="list-item">
                            circulatiepompen zijn onbekend;
                          </li>
                          <li className="list-item">
                            bij keuze voor bevochtiging wordt uitgegaan van
                            elektrische stoombevochtiging;
                          </li>
                          <li className="list-item">
                            voor het gemiddeld geïnstalleerd
                            verlichtingsvermogen LED/T5/T8 is respectievelijk
                            5/9/12 W/m2 aangehouden (zonder
                            nieuwwaarde-compensatie);
                          </li>
                          <li className="list-item">
                            schakeling verlichting is afhankelijk van personen
                            middels 'automatisch aan/uit'.
                          </li>
                        </ul>
                      </>
                    ) : (
                      ""
                    )
                  )
                  setInfoModalOpen(true)
                }}
              />
            </div>

            {currentPage !== 1 && activeRoom !== "Resultaten" && (
              <div className="pb-5">
                <h3 className="mt-4 font-mulish font-bold text-gray text-14">
                  RESULTATEN
                </h3>
                <div className="mt-4 mb-10 border-4 border-blueGrayOpacity p-2 pt-0">
                  <h3
                    className="text-darkBlue text-h3 font-bold font-mulish lg:text-28 laptop:text-14 break-words mb-5"
                    style={{ fontWeight: 700 }}
                  >
                    {projectDetails.Gebouwnaam}
                  </h3>
                  <div className="mt-2 grid grid-row-4 gap-4 mr-2 lg:mr-20 items-center w-3/4 ">
                    <div>
                      <div className="flex flex-col bg-darkBlue px-1">
                        <p className="text-white text-sm p-1">BENG 1</p>
                      </div>
                      <div className="flex flex-row mt-2">
                        <p className="text-h3 text-darkBlue ml-2 font-bold">
                          {Beng1}
                        </p>
                        <p className="text-h5 ml-2 mt-3">kWh/m²</p>
                        <img
                          src={InfoIcon}
                          className="cursor-pointer h-5 mt-3 ml-2"
                          alt="info"
                          onClick={() => {
                            setInfoModalText(
                              "BENG 1: de maximale energiebehoefte in kWh per m2 gebruiksoppervlak per jaar (kWh/m2.jr)."
                            )
                            setInfoModalTitle("BENG 1")
                            setInfoModalOpen(true)
                          }}
                        />
                      </div>
                    </div>
                    {currentPage === 3 && (
                      <>
                        <div>
                          <div className="flex flex-col bg-darkBlue px-1">
                            <p className="text-white text-sm p-1">BENG 2</p>
                          </div>
                          <div className="flex flex-row mt-2">
                            <p className="text-h3 text-darkBlue ml-2 font-bold">
                              {Beng2}
                            </p>
                            <p className="text-h5 ml-2 mt-3">kWh/m²</p>
                            <img
                              src={InfoIcon}
                              className="cursor-pointer h-5 mt-3 ml-2"
                              alt="info"
                              onClick={() => {
                                setInfoModalText(
                                  "BENG 2: het maximale primair fossiel energiegebruik in kWh per m2 gebruiksoppervlak per jaar (kWh/m2.jr)."
                                )
                                setInfoModalTitle("BENG 2")
                                setInfoModalOpen(true)
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="flex flex-col bg-darkBlue px-1">
                            <p className="text-white text-sm p-1">BENG 3</p>
                          </div>
                          <div className="flex flex-row mt-2">
                            <p className="text-h3 text-darkBlue ml-2 font-bold">
                              {Beng3}
                            </p>
                            <p className="text-h5 ml-2 mt-3">%</p>
                            <img
                              src={InfoIcon}
                              className="cursor-pointer h-5 mt-3 ml-2"
                              alt="info"
                              onClick={() => {
                                setInfoModalText(
                                  "BENG 3: het minimale aandeel hernieuwbare energie in procenten (%)."
                                )
                                setInfoModalTitle("BENG 3")
                                setInfoModalOpen(true)
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="bg-lightBlue px-1 ">
                            <p className="text-white text-sm p-1">
                              Energielabel
                            </p>
                          </div>
                          <div className="flex flex-row items-center">
                            <div
                              className="mt-2 py-1 px-3 text-white text-h3 pr-6"
                              style={{
                                backgroundColor: Energielabel?.color,
                                clipPath:
                                  "polygon(75% 1%, 100% 50%, 75% 100%, 0 100%, 0 0)",
                              }}
                            >
                              {Energielabel?.name}
                            </div>
                            <img
                              src={InfoIcon}
                              className="cursor-pointer w-6 mt-5 ml-2"
                              alt="info"
                              onClick={() => {
                                setInfoModalText(
                                  "Energielabel: de energieprestatieklasse van het gebouw, gebaseerd op het maximaal primair fossiel energieverbruik (BENG 2)."
                                )
                                setInfoModalTitle("Energielabel")
                                setInfoModalOpen(true)
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Container>
        </div>
      </div>

      <div className="lg:hidden overflow-y-scroll h-full">
        {/* SMALL SCREEN SIDEBAR */}
        <div
          className={`absolute h-full bg-lightBlue z-20 laptop:w-1/5 ${
            open ? "openSidebar" : "closeSidebar"
          }`}
          style={{ background: "#ffffff" }}
        >
          <Container>
            <div className="relative w-full md:pt-20">
              <div
                className="absolute top-1 md:top-20 right-5"
                onClick={closeSidebar}
              >
                <AiOutlineClose color="black" className="md:h-8 md:w-8" />
              </div>
              <GreyHeadline text="NAVIGATIE" />
              <div className="mt-5">
                <SidebarItem
                  text="Gegevens"
                  path={path}
                  onClick={() => {
                    activeToolReduxObject.activeTool === "BENGcheck"
                      ? history.push("/BENGcheck/projectgegevens")
                      : history.push("/energielabelcheck/projectgegevens")
                    dispatch(changeActiveRoom("Gegevens"))
                    dispatch(fillData({ ...stateData, currPage: 1 }))
                    handleItemClick("Gegevens")
                  }}
                  active={activeItem === "Gegevens"}
                />

                {rooms.map((elem, index) => (
                  <RoomItem
                    text={elem.name}
                    key={index}
                    active={elem.name === activeRoom}
                    onClick={() => {
                      if (email === "") return
                      dispatch(changeActiveRoom(elem.name, index))
                      setTimeout(() => {
                        history.push({
                          pathname: `/binnenklimaat/room-step/1/${index}`,
                        })
                      }, 100)
                    }}
                  />
                ))}

                <SidebarItem
                  text="Resultaten"
                  path={path}
                  onClick={() => {
                    if (
                      (currentPage === 1 && !isCalculationDone) ||
                      (currentPage === 2 && !isCalculationDone)
                    )
                      return
                    history.push("/energielabelcheck/resultaten")
                    dispatch(changeActiveRoom("Resultaten"))
                    dispatch(toggleIsCalculationDone(true))
                    handleItemClick("Resultaten")
                  }}
                  active={activeItem === "Resultaten"}
                />
              </div>
              <div className="mt-5">
                <ResetButton
                  text="Reset het formulier"
                  onClick={() => {
                    closeSidebar(true)
                    setModalOpen(true)
                  }}
                />
                <AboutTheToolButton
                  text="Over de tool"
                  onClick={() => setInfoModalOpen(true)}
                />
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
