/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import {
  addRuimteData,
  addHeatingClass,
  addCoolingClass,
  addAirFlowHeadLoadData,
  toggleDoneSteps,
} from "redux/actions"
import { airFlowHeatLoad } from "constants/airFlowHeatLoad"
import { useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import Select from "components/Select"
import Input from "components/Input"
import Button from "components/Button"
import LoadingSpinner from "components/LoadingSpinner"

import ArrowRight from "assets/images/arrow-right.svg"

const RuimteStep = ({ setStep, room, index }) => {
  const dispatch = useDispatch()
  const rooms = useSelector((state) => state.rooms.rooms)
  const history = useHistory()
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)

  const [inputValue, setInputValue] = useState(null)
  const [formGood, setFormGood] = useState(true)
  const [facadeGood, setFacadeGood] = useState(true)
  const [roomValues, setRoomValues] = useState({
    spaceMeasures: [{ name: "naam", value: "" }],
    spaceUse: [
      { label: "Bezetting", value: "" },
      { label: "Interne warmtebelasting", value: "" },
    ],
    facade: [
      { name: "Percentage glas in de gevel", value: "" },
      { name: "Is er buitenzonwering?", value: "" },
      { name: "Isolatiewaarde glas", value: "" },
      { name: "Helderheid glas", value: "" },
    ],
  })

  const setValue = (value) => {
    //When user choose the option, we check what is coresponding value for that option
    //and add it to local state and to airFlowHeatLoad object that is saving those values.
    switch (value) {
      case "1.8 x 5.4": {
        setInputValue(9.7)
        airFlowHeatLoad.totaalVloeroppervlak = 9.7
        dispatch(addAirFlowHeadLoadData(index, "totaalVloeroppervlak", 9.7))
        break
      }
      case "3.6 x 5.4": {
        setInputValue(19.4)
        airFlowHeatLoad.totaalVloeroppervlak = 19.4
        dispatch(addAirFlowHeadLoadData(index, "totaalVloeroppervlak", 19.4))
        break
      }
      case "5.4 x 5.4": {
        setInputValue(29.2)
        airFlowHeatLoad.totaalVloeroppervlak = 29.2
        dispatch(addAirFlowHeadLoadData(index, "totaalVloeroppervlak", 29.2))
        break
      }
      case "7.2 x 5.4": {
        setInputValue(38.9)
        airFlowHeatLoad.totaalVloeroppervlak = 38.9
        dispatch(addAirFlowHeadLoadData(index, "totaalVloeroppervlak", 38.9))
        break
      }
      default: {
        setInputValue(9.7)
        airFlowHeatLoad.totaalVloeroppervlak = 9.7
        dispatch(addAirFlowHeadLoadData(index, "totaalVloeroppervlak", 9.7))
        break
      }
    }
  }

  const checkFacadeOnSelectChange = () => {
    const data = { ...roomValues }
    const facade = data.facade.filter(
      (value) => value.name !== "Is er buitenzonwering?"
    )

    if (
      (facade[0].value === "n.v.t" &&
        facade[1].value === "n.v.t" &&
        facade[2].value === "n.v.t") ||
      (facade[0].value !== "n.v.t" &&
        facade[1].value !== "n.v.t" &&
        facade[2].value !== "n.v.t")
    ) {
      setFacadeGood(true)
    } else {
      setFacadeGood(false)
    }
  }

  const goToNextIfFacadeIsGood = () => {
    //Here we will check if all of them are N.V.T or none of them are N.V.T except "Is er buitenzonwering?"
    //Only way to go to the next step is if they are all N.V.T or none of them are N.V.T except "Is er buitenzonwering?", combination is not allowed
    const data = { ...roomValues }
    const facade = data.facade.filter(
      (value) => value.name !== "Is er buitenzonwering?"
    )
    // eslint-disable-next-line
    if (
      (facade[0].value === "n.v.t" &&
        facade[1].value === "n.v.t" &&
        facade[2].value === "n.v.t") ||
      (facade[0].value !== "n.v.t" &&
        facade[1].value !== "n.v.t" &&
        facade[2].value !== "n.v.t")
    ) {
      setFacadeGood(true)
      dispatch(addRuimteData(roomValues, index))
      setStep(2)
      history.push(`/binnenklimaat/room-step/${2}/${index}`)
    } else {
      setFacadeGood(false)
    }
  }

  const checkForm = () => {
    if (roomValues.spaceMeasures[0].value === "") return setFormGood(false)
    for (var i = 0; i < roomValues.spaceUse.length; i++) {
      if (roomValues.spaceUse[i].value === "") {
        return setFormGood(false)
      }
    }
    for (var j = 0; j < roomValues.facade.length; j++) {
      if (roomValues.facade[j].value === "") {
        return setFormGood(false)
      }
    }
    setFormGood(true)
    goToNextIfFacadeIsGood()
  }

  useEffect(() => {
    setIsLoading(true)
    setRoomValues({
      spaceMeasures: [{ name: "naam", value: "" }],
      spaceUse: [
        { label: "Bezetting", value: "" },
        { label: "Interne warmtebelasting", value: "" },
      ],
      facade: [
        { name: "Percentage glas in de gevel", value: "" },
        { name: "Is er buitenzonwering?", value: "" },
        { name: "Isolatiewaarde glas", value: "" },
        { name: "Helderheid glas", value: "" },
      ],
    })
    //If data is already filled and we want to edit it, fill old data from redux.
    setIsLoading(true)
    if (!room || !room[0].stepsDone.first) {
      setIsLoading(false)
    } else {
      let roomVal = room[0]
      setInputValue(roomVal?.airFlowHeatLoadData?.totaalVloeroppervlak || null)
      setRoomValues({ ...roomValues, ...roomVal })
    }
  }, [room, params.room])

  useEffect(() => {
    //This useEffect is to force application to re-render so proper information is filled in.
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 10)
  }, [params.room])

  return isLoading ? (
    <div className="h-full w-full flex items-center justify-center mt-44">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="pt-10 pb-24">
      <p className="font-bold text-h4 text-darkBlue">Hoe groot is de ruimte?</p>
      <div className="flex flex-col md:flex-row md:space-x-5 mt-5">
        <Select
          options={[
            { value: "1.8 x 5.4", label: "1.8 x 5.4 m", class: "A" },
            { value: "3.6 x 5.4", label: "3.6 x 5.4 m", class: "B" },
            { value: "5.4 x 5.4", label: "5.4 x 5.4 m", class: "C" },
            { value: "7.2 x 5.4", label: "7.2 x 5.4 m", class: "D" },
          ]}
          label="Afmetingen ruimte"
          info
          infoText="Bij de berekeningen van wintercomfort en zomercomfort wordt gebruik gemaakt van standaard ruimte afmetingen. Kies voor de referentie vertrekken de afmetingen die zo dicht mogelijk in de buurt komen van de werkelijke situatie"
          value={{
            value: roomValues.spaceMeasures[0].value,
            label: roomValues.spaceMeasures[0].value,
          }}
          onChange={(e) => {
            var val = { ...roomValues }
            val.spaceMeasures[0].value = e.value
            setRoomValues({ ...val })
            setValue(e.value)
            dispatch(addHeatingClass(1, e.class, index))
            dispatch(addCoolingClass(2, e.class, index))
            dispatch(addRuimteData(roomValues, index))
          }}
          invalid={!formGood && roomValues.spaceMeasures[0].value === ""}
        />
        <Input
          label="Totaal vloeroppervlak"
          disabled
          value={inputValue}
          inputExp={
            <p className="relative">
              m<span className="absolute text-8">2</span>
            </p>
          }
        />
      </div>
      <p className="font-bold text-h4 text-darkBlue mt-10">
        Hoe wordt de ruimte gebruikt?
      </p>
      <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-5 mt-5">
        <Input
          label="Bezetting"
          inputExp="personen"
          info
          type="number"
          value={roomValues.spaceUse[0].value}
          invalid={!formGood && roomValues.spaceUse[0].value === ""}
          onChange={(e) => {
            if (e.target.value < 0) return
            var val = { ...roomValues }
            val.spaceUse[0].value = e.target.value
            setRoomValues({ ...val })
            dispatch(
              addAirFlowHeadLoadData(
                index,
                "bezzeting",
                parseInt(e.target.value)
              )
            )
            if (rooms[index].airFlowHeatLoadData.ventilatiehoeveelheid !== 0) {
              dispatch(
                addAirFlowHeadLoadData(
                  index,
                  "ventilatiehoeveelheidPerson",
                  rooms[index].airFlowHeatLoadData.ventilatiehoeveelheid /
                    parseInt(e.target.value)
                )
              )
            }
            dispatch(addRuimteData(roomValues, index))
          }}
          infoText="Voer het aantal personen in waarvoor de ruimte is ontworpen. Het definitief aantal personen per ruimte wordt in de omgevingsvergunning vastgelegd. Schaal het aantal personen op/af als de gekozen afmetingen afwijken van de werkelijk situatie. Op basis van het aantal personen wordt de luchtverversing per persoon bepaald. Daarnaast wordt de warmtebelasting a.g.v. personen meegenomen in de berekening van het zomercomfort."
        />
        <Select
          options={[
            { value: "Laag (5 W/m²)", label: "Laag (5 W/m²)", pickValue: 5 },
            {
              value: "Gemiddeld (15 W/m²)",
              label: "Gemiddeld (15 W/m²)",
              pickValue: 15,
            },
            { value: "Hoog (25 W/m²)", label: "Hoog (25 W/m²)", pickValue: 25 },
          ]}
          info
          infoText="De interne warmtebelasting omvat de gemiddelde warmtebelasting a.g.v. verlichting en gebruiksapparatuur. Kies het niveau (laag/gemiddeld/hoog) dat van toepassing is. De interne warmtebelasting wordt meegenomen in de berekening van het zomercomfort."
          label="Interne warmtebelasting"
          value={{
            value: roomValues.spaceUse[1].value,
            label: roomValues.spaceUse[1].value,
          }}
          onChange={(e) => {
            var val = { ...roomValues }
            val.spaceUse[1].value = e.value
            setRoomValues({ ...val })
            dispatch(
              addAirFlowHeadLoadData(index, "interneWarmtelast", e.pickValue)
            )
            dispatch(addRuimteData(roomValues, index))
          }}
          invalid={!formGood && roomValues.spaceUse[1].value === ""}
        />
      </div>
      <p className="font-bold text-h4 text-darkBlue mt-10">
        Hoe ziet de gevel er uit?
      </p>
      <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-5 mt-5">
        <Select
          options={[
            { value: "n.v.t", label: "n.v.t", class: "A" },
            { value: "35%", label: "35%", class: "B" },
            { value: "55%", label: "55%", class: "C" },
            { value: "75%", label: "75%", class: "D" },
          ]}
          info
          infoText="Bij de berekeningen van het wintercomfort en het zomercomfort wordt gebruik gemaakt van een standaard percentages glas (30%/50%/70%) Kies voor de referentie vertrekken het percentage glas dat zo dicht mogelijk in de buurt komt van de werkelijke situatie. Bij een inpandig vertrek kiest u voor 0% glas."
          value={{
            value: roomValues.facade[0].value,
            label: roomValues.facade[0].value,
          }}
          invalid={
            (!formGood && roomValues.facade[0].value === "") || !facadeGood
          }
          label="Percentage glas in de gevel"
          onChange={(e) => {
            var val = { ...roomValues }
            if (val.facade[0].value != e.value) {
              delete room[0]["orientation"]
              delete room[0]["space"]
              dispatch(toggleDoneSteps(index, "second", false))
            }
            val.facade[0].value = e.value
            checkFacadeOnSelectChange()
            setRoomValues({ ...val })
            dispatch(addHeatingClass(2, e.class, index))
            dispatch(addCoolingClass(3, e.class, index))
            dispatch(addRuimteData(roomValues, index))
          }}
        />
        <Select
          options={[
            {
              value: "Ja, buitenzonwering",
              label: "Ja, buitenzonwering",
              class: "A",
            },
            {
              value: "Nee, geen zonwering of binnenzonwering",
              label: "Nee, geen zonwering of binnenzonwering",
              class: "B",
            },
          ]}
          label="Is er buitenzonwering?"
          info
          infoText="Voer hier ‘Ja’ in, indien er sprake is van volwaardige buitenzonwering zoals screens of uitvalschermen. Dit wordt meegenomen in de berekening van het zomercomfort. Hierbij wordt een schakelmoment van 250 W/m² aangehouden."
          value={{
            value: roomValues.facade[1].value,
            label: roomValues.facade[1].value,
          }}
          invalid={!formGood && roomValues.facade[1].value === ""}
          onChange={(e) => {
            var val = { ...roomValues }
            val.facade[1].value = e.value
            setRoomValues({ ...val })
            dispatch(addCoolingClass(5, e.class, index))
            dispatch(addRuimteData(roomValues, index))
          }}
        />
      </div>
      <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-5 mt-5">
        <Select
          options={[
            { value: "n.v.t", label: "n.v.t", class: "A" },
            {
              value: "HR++ glas (U=1.1)",
              label: "HR++ glas (U=1.1)",
              class: "B",
            },
            {
              value: "Triple glas (U=0.8)",
              label: "Triple glas (U=0.8)",
              class: "C",
            },
          ]}
          label="Isolatiewaarde glas"
          info
          value={{
            value: roomValues.facade[2].value,
            label: roomValues.facade[2].value,
          }}
          invalid={
            (!formGood && roomValues.facade[2].value === "") || !facadeGood
          }
          onChange={(e) => {
            var val = { ...roomValues }
            if (val.facade[2].value != e.value) {
              delete room[0]["orientation"]
              delete room[0]["space"]
              dispatch(toggleDoneSteps(index, "second", false))
            }
            val.facade[2].value = e.value
            checkFacadeOnSelectChange()
            setRoomValues({ ...val })
            dispatch(addHeatingClass(3, e.class, index))
            dispatch(addRuimteData(roomValues, index))
          }}
          infoText="Maak een keuze voor een type glas met bijbehorende U-waarde. De U-waarde wordt meegenomen in de berekening van het wintercomfort. Hierbij wordt een kozijn met een U-waarde van 2,4 W/(m².K) en 10% van het oppervlak aangehouden."
        />
        <Select
          options={[
            { value: "n.v.t", label: "n.v.t", class: "A" },
            {
              value: "Blank glas (ZTA=0.6)",
              label: "Blank glas (ZTA=0.6)",
              class: "B",
            },
            {
              value: "Zonwerend glas (ZTA=0.3)",
              label: "Zonwerend glas (ZTA=0.3)",
              class: "C",
            },
          ]}
          label="Zonwerendheid glas"
          value={{
            value: roomValues.facade[3].value,
            label: roomValues.facade[3].value,
          }}
          invalid={
            (!formGood && roomValues.facade[3].value === "") || !facadeGood
          }
          onChange={(e) => {
            var val = { ...roomValues }
            if (val.facade[3].value != e.value) {
              delete room[0]["orientation"]
              delete room[0]["space"]
              dispatch(toggleDoneSteps(index, "second", false))
            }
            val.facade[3].value = e.value
            checkFacadeOnSelectChange()
            setRoomValues({ ...val })
            dispatch(addCoolingClass(4, e.class, index))
            dispatch(addRuimteData(roomValues, index))
          }}
          info
          infoText="Maak een keuze voor een type glas met bijbehorende ZTA-waarde. De ZTA-waarde wordt meegenomen in de berekening van het zomercomfort."
        />
      </div>
      <div
        className="mt-5"
        data-for="test"
        data-tip="Je hebt een ongeldige combinatie van parameters opgegeven. Zorg dat alle glas-gerelateerde parameters een geldige waarde hebben. Indien er geen glas is, zet alle parameters op n.v.t."
      >
        <Button
          text="NAAR ORIËNTATIE"
          isDark={false}
          icon={ArrowRight}
          onClick={() => {
            checkForm()
          }}
        />
        {!facadeGood && (
          <ReactTooltip
            id="test"
            // type='light'
            effect="solid"
            delayHide={100}
            padding={"8px"}
            backgroundColor="#af4860fd"
            className="w-80 bg-darkBlue text-black filter drop-shadow-sm tooltip-background"
          />
        )}
      </div>
    </div>
  )
}

export default RuimteStep
