import React from "react"

const Stepper = ({ stepOne, stepTwo, stepThree, setStep }) => {
  return (
    <div className="pb-5">
      <div className="mb-4 pb-4">
        <div className="flex items-center">
          <div
            className="flex items-center relative cursor-pointer"
            onClick={() => setStep(1)}
          >
            <div
              className={`rounded-full h-12 w-12 py-3 ${
                stepOne ? "bg-lightBlue" : "bg-gray"
              }`}
            >
              <p className="text-white font-bold text-center text-h4">1</p>
            </div>
            <div
              className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold uppercase ${
                stepOne ? "text-lightBlue" : "text-gray"
              } `}
            >
              Ruimte
            </div>
          </div>
          <div
            className={`flex-auto border-t-4 transition duration-500 ease-in-out ${
              stepTwo ? "border-lightBlue" : "border-gray"
            }`}
          ></div>
          <div
            className="flex items-center text-gray-500 relative cursor-pointer"
            onClick={() => setStep(2)}
          >
            <div
              className={`rounded-full h-12 w-12 py-3 ${
                stepTwo ? "bg-lightBlue" : "bg-gray"
              }`}
            >
              <p className="text-white font-bold text-center text-h4">2</p>
            </div>
            <div
              className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold uppercase ${
                stepTwo ? "text-lightBlue" : "text-gray"
              } `}
            >
              Oriëntatie
            </div>
          </div>
          <div
            className={`flex-auto border-t-4 transition duration-500 ease-in-out ${
              stepThree ? "border-lightBlue" : "border-gray"
            }`}
          ></div>
          <div
            className="flex items-center text-gray-500 relative cursor-pointer"
            onClick={() => setStep(3)}
          >
            <div
              className={`rounded-full h-12 w-12 py-3 ${
                stepThree ? "bg-lightBlue" : "bg-gray"
              }`}
            >
              <p className="text-white font-bold text-center text-h4">3</p>
            </div>
            <div
              className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold uppercase ${
                stepThree ? "text-lightBlue" : "text-gray"
              } `}
            >
              Installatie
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stepper
