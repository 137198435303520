import React from "react"

import { View, Text, Page, StyleSheet, Font } from "@react-pdf/renderer"
import font from "fonts/Mulish-Bold.ttf"

import { Header, Footer, Date } from ".."

Font.register({ family: "Mulish-Bold", src: font })

const FirstPage = ({ details }) => (
  <Page style={styles.body}>
    <Header />
    <View style={styles.projectInfo}>
      <Text style={styles.project}>Project</Text>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Projectnaam</Text>
          <Text style={styles.projectData}>{details?.Projectnaam}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Projectnummer</Text>
          <Text style={styles.projectData}>{details?.Projectnummer}</Text>
        </View>
      </View>
      <Text style={styles.infoHead}>Opgesteld voor</Text>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Contactpersoon</Text>
          <Text style={styles.projectData}>{details.Contactpersoon}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Organisatie</Text>
          <Text style={styles.projectData}>{details?.Organisatie}</Text>
        </View>
      </View>
      <Text style={styles.infoHead}>Opgesteld door</Text>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Naam</Text>
          <Text style={styles.projectData}>{details.Naam}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Bedrijf</Text>
          <Text style={styles.projectData}>{details?.Bedrijf}</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>E-mail</Text>
          <Text style={styles.projectData}>{details?.Email}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Telefoonnummer</Text>
          <Text style={styles.projectData}>{details?.Telefoonnummer}</Text>
        </View>
      </View>
    </View>
    <Date />
    <Footer />
  </Page>
)

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  headlineDescription: {
    fontSize: 10,
  },
  projectInfo: {
    marginTop: "20px",
  },
  project: {
    color: "#2E276A",
    fontFamily: "Mulish-Bold",
    marginBottom: "20px",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
  infoWrap: {
    width: "50%",
  },
  projectLabel: {
    color: "#009FDF",
    fontSize: "10px",
    marginBottom: "8px",
  },
  projectData: {
    fontSize: "10px",
  },
  infoHead: {
    marginTop: "20px",
    marginBottom: "10px",
    color: "#2E276A",
    fontSize: "15px",
    fontFamily: "Mulish-Bold",
  },
  dateWrapper: {
    position: "absolute",
    bottom: "10%",
    left: "35px",
  },
  dateLabel: {
    color: "#009FDF",
    fontSize: "10px",
  },
  date: {
    fontSize: "13px",
    marginTop: "5px",
  },
  asterixSign: {
    fontSize: 5,
    marginLeft: 2,
    color: "#6C6C6C",
  },
  textWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    fontFamily: "Mulish-Bold",
  },
  three: {
    position: "absolute",
    fontSize: 6,
    top: -3,
    left: 10,
  },
  headline: {
    color: "#2E276A",
    fontSize: 14,
    marginBottom: 13,
    fontFamily: "Mulish-Bold",
    padding: "0 10px",
    paddingTop: "5px",
  },
  container: {
    backgroundColor: "#4F879D1A",
    marginTop: 10,
  },
  resultsContainer: {
    width: "100%",
    // backgroundColor: "#4F879D1A",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "20px 10px",
  },
  resultsWrapper: {
    marginRight: 5,
  },
  nameClass: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  classWrap: {
    height: 20,
    width: 20,
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  classA: {
    backgroundColor: "#00A651",
  },
  classB: {
    backgroundColor: "#8CC63F",
  },
  classC: {
    backgroundColor: "#FFD500",
  },
  classD: {
    backgroundColor: "#EE1D23",
  },
  lucht: {
    marginRight: 13,
  },
  winter: {
    marginRight: 20,
  },
  zomer: {
    marginRight: 23,
  },
  classLetter: {
    color: "#ffffff",
    fontSize: 12,
  },
  name: {
    color: "#2E276A",
    fontSize: 8,
    marginRight: 5,
  },
  roomInfoWraper: {
    marginTop: 10,
    marginLeft: 20,
  },
  roomInfoLabel: {
    color: "#009FDF",
    fontSize: 8,
  },
  roomInfoText: {
    fontSize: 8,
    marginTop: 3,
  },
  unitText: {
    fontSize: 8,
    fontWeight: "normal",
  },
  uur: {
    marginLeft: 3,
  },
})

export default FirstPage
