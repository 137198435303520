import heatingScenario from "calculationScenarios/heating-scenario.json"
import coolingScenario from "calculationScenarios/cooling-scenario.json"

import { airFlowHeatLoad } from "constants/airFlowHeatLoad"

export const calculateClasses = (classes, options) => {
  //When we want to calculate classes, first we need to check if there is given scenario for classes that user sent.
  //Example: First we will check heating-scenario. User has sent BAAA as classes, if there is scenario with that ID
  //then we save that scenario in matchScenario variable

  let matchScenarioHeating
  let matchCoolingScenario

  let matchingHeatingClass
  let matchCoolingClass
  let luchtkwaliteitClass

  // console.log('POWER AVAILABLE', options)

  if (classes.heatingClasses.startsWith("C")) {
    //matchingHeatingClass = 'C'
    matchScenarioHeating = heatingScenario.find((value) => value.ID === "C")

    if (
      options.heating.optionA >=
      parseFloat(matchScenarioHeating.classA.replace(",", "."))
    ) {
      matchingHeatingClass = "A"
    } else if (
      options.heating.optionB >=
      parseFloat(matchScenarioHeating.classB.replace(",", "."))
    ) {
      matchingHeatingClass = "B"
    } else if (
      options.heating.optionC >=
      parseFloat(matchScenarioHeating.classC.replace(",", "."))
    ) {
      matchingHeatingClass = "C"
    } else {
      matchingHeatingClass = "D"
    }
  }
  // else if(options.heating.optionA === 'N/A'){
  //     matchingHeatingClass = 'B'
  // }else if(options.heating.optionB === 'N/A'){
  //     matchingHeatingClass = 'C'
  // }else if(options.heating.optionC === 'N/A'){
  //     matchingHeatingClass = 'D'
  // }
  else {
    matchScenarioHeating = heatingScenario.find(
      (value) => value.ID === classes.heatingClasses
    )
    if (!matchScenarioHeating)
      console.error('CAN"T FIND MATCHING SCENARIO FOR HEATING')

    if (
      options.heating.optionA >=
      parseFloat(matchScenarioHeating.classA.replace(",", "."))
    ) {
      matchingHeatingClass = "A"
    } else if (
      options.heating.optionB >=
      parseFloat(matchScenarioHeating.classB.replace(",", "."))
    ) {
      matchingHeatingClass = "B"
    } else if (
      options.heating.optionC >=
      parseFloat(matchScenarioHeating.classC.replace(",", "."))
    ) {
      matchingHeatingClass = "C"
    } else {
      matchingHeatingClass = "D"
    }
  }

  if (classes.coolingClasses.startsWith("C")) {
    matchCoolingScenario = coolingScenario.find((value) => value.ID === "C")

    if (
      options.cooling.optionA >=
      parseFloat(matchCoolingScenario.classA.replace(",", "."))
    ) {
      matchCoolingClass = "A"
    } else if (
      options.cooling.optionB >=
      parseFloat(matchCoolingScenario.classB.replace(",", "."))
    ) {
      matchCoolingClass = "B"
    } else if (
      options.cooling.optionC >=
      parseFloat(matchCoolingScenario.classC.replace(",", "."))
    ) {
      matchCoolingClass = "C"
    } else {
      matchCoolingClass = "D"
    }
  }
  // else if(options.cooling.optionA === 'N/A'){
  //     matchCoolingClass = 'B'
  // }else if(options.cooling.optionB === 'N/A'){
  //     matchCoolingClass = 'C'
  // }else if(options.cooling.optionC === 'N/A'){
  //     matchCoolingClass = 'D'
  // }
  else {
    matchCoolingScenario = coolingScenario.find(
      (value) => value.ID === classes.coolingClasses
    )
    if (!matchCoolingScenario)
      console.error('CAN"T FIND MATCHING SCENARIO FOR COOLING')

    if (
      options.cooling.optionA >=
      parseFloat(matchCoolingScenario.classA.replace(",", "."))
    ) {
      matchCoolingClass = "A"
    } else if (
      options.cooling.optionB >=
      parseFloat(matchCoolingScenario.classB.replace(",", "."))
    ) {
      matchCoolingClass = "B"
    } else if (
      options.cooling.optionC >=
      parseFloat(matchCoolingScenario.classC.replace(",", "."))
    ) {
      matchCoolingClass = "C"
    } else {
      matchCoolingClass = "D"
    }
  }

  if (airFlowHeatLoad.ventilatiehoeveelheidPerson >= 60) {
    luchtkwaliteitClass = "A"
  } else if (airFlowHeatLoad.ventilatiehoeveelheidPerson >= 40) {
    luchtkwaliteitClass = "B"
  } else if (airFlowHeatLoad.ventilatiehoeveelheidPerson >= 25) {
    luchtkwaliteitClass = "C"
  } else {
    luchtkwaliteitClass = "D"
  }

  // console.log('Luchtdebiet:', airFlowHeatLoad.luchtdebiet )
  // console.log('Interne warmtelast:', airFlowHeatLoad.interneWarmtelastSum )

  // console.log(' ')
  // console.log("COOLING SCENARIO:", matchCoolingScenario)
  // console.log("HEATING SCENARIO:", matchScenarioHeating)

  // console.log('OPTIONS', options)
  // console.log('CLASSES', classes)

  // console.log("HEATING / WINTERCOMFORT: ", matchingHeatingClass)
  // console.log("COOLING / ZOMERCOMFORT: ", matchCoolingClass)

  //After that we check if available power IS GREATER THAN required power.
  //That means, if calculated options that we get as a second argument is higher that scenario we got from JSON

  return {
    wintercomfort: matchingHeatingClass,
    zomercomfort: matchCoolingClass,
    luchtkwaliteit: luchtkwaliteitClass,
  }
}
