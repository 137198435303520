import React from "react"

import { View, Page, Text, StyleSheet, Font } from "@react-pdf/renderer"
import font from "fonts/Mulish-Bold.ttf"

import { Footer } from ".."

const Classes = ({ className }) => {
  switch (className) {
    case "A":
      return (
        <View style={[styles.classWrap, styles.classA]}>
          <Text style={styles.classLetter}>A</Text>
        </View>
      )
    case "B":
      return (
        <View style={[styles.classWrap, styles.classB]}>
          <Text style={styles.classLetter}>B</Text>
        </View>
      )
    case "C":
      return (
        <View style={[styles.classWrap, styles.classC]}>
          <Text style={styles.classLetter}>C</Text>
        </View>
      )
    default:
      return (
        <View style={[styles.classWrap, styles.classD]}>
          <Text style={styles.classLetter}>D</Text>
        </View>
      )
  }
}

const RoomInfo = ({ label, text, unit }) => (
  <View style={styles.roomInfoWraper}>
    <Text style={styles.roomInfoLabel}>{label}</Text>
    {unit ? (
      <Text style={styles.roomInfoText}>
        {text} {unit}
      </Text>
    ) : (
      <Text style={styles.roomInfoText}>{text}</Text>
    )}
  </View>
)

Font.register({ family: "Mulish-Bold", src: font })

const ResultsPage = ({ results }) => (
  <Page style={styles.body} wrap>
    <Text style={styles.headline}>Resultaten</Text>
    {results.length > 0 &&
      results.map((elem) => (
        <View style={styles.container} wrap={false}>
          <Text style={styles.headline}>{elem?.name}</Text>
          <View style={styles.resultsContainer}>
            <View style={styles.resultsWrapper}>
              <View style={styles.nameClass}>
                <Text style={[styles.name, styles.lucht]}>LUCHTVERVERSING</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Classes className={elem.calculatedClasses.luchtkwaliteit} />
                  {elem?.valueTooHigh && (
                    <Text style={styles.asterixSign}>||</Text>
                  )}
                </View>
              </View>
              <View style={styles.nameClass}>
                <Text style={[styles.name, styles.winter]}>WINTERCOMFORT</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Classes className={elem.calculatedClasses.wintercomfort} />
                  {elem?.climateConcept == "Radiatoren + topkoeling" && (
                    <Text style={styles.asterixSign}>|</Text>
                  )}
                  {elem?.climateConcept === "Vloerverwarming/-koeling" && (
                    <Text style={styles.asterixSign}>|</Text>
                  )}
                </View>
              </View>
              <View style={styles.nameClass}>
                <Text style={[styles.name, styles.zomer]}>ZOMERCOMFORT</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Classes className={elem.calculatedClasses.zomercomfort} />
                  {elem?.climateConcept == "Radiatoren + topkoeling" && (
                    <Text style={styles.asterixSign}>|</Text>
                  )}
                  {elem?.climateConcept === "Vloerverwarming/-koeling" && (
                    <Text style={styles.asterixSign}>|</Text>
                  )}
                </View>
              </View>
            </View>
            <View style={styles.resultsWrapper}>
              <RoomInfo
                label="Afmetingen ruimte"
                unit="m"
                text={elem?.spaceMeasures[0].value}
              />
              <RoomInfo
                label="Bezetting"
                unit="personen"
                text={elem?.spaceUse[0].value}
              />
              <RoomInfo
                label="Interne warmtebelasting"
                text={elem?.spaceUse[1].value}
              />
            </View>
            <View style={styles.resultsWrapper}>
              <RoomInfo
                label="Percentage glas in de gevel"
                text={elem?.facade[0].value}
              />
              <RoomInfo
                label="Isolatiewaarde glas"
                text={elem?.facade[2].value}
              />
              <RoomInfo label="Helderheid glas" text={elem?.facade[3].value} />
              <RoomInfo
                label="Is er buitenzonwering?"
                text={elem?.facade[1].value}
              />
            </View>
            <View style={styles.resultsWrapper}>
              <RoomInfo label="Type vertrek" text={elem?.space} />
              <RoomInfo
                label="Oriëntatie van de gevel"
                text={elem?.orientation}
              />
              <RoomInfo
                label="Ventilatiehoeveelheid"
                unit="m3/uur"
                text={elem?.instalationCapacity[0].value}
              />
              <RoomInfo label="Klimaatconcept" text={elem?.climateConcept} />
            </View>
          </View>
        </View>
      ))}
    {/* <Footer /> */}
  </Page>
)

const styles = StyleSheet.create({
  asterixSign: {
    fontSize: 5,
    marginLeft: 2,
    color: "#6C6C6C",
  },
  textWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    fontFamily: "Mulish-Bold",
  },
  three: {
    position: "absolute",
    fontSize: 6,
    top: -3,
    left: 10,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  headline: {
    color: "#2E276A",
    fontSize: 14,
    marginBottom: 13,
    fontFamily: "Mulish-Bold",
    padding: "0 10px",
    paddingTop: "5px",
  },
  container: {
    backgroundColor: "#4F879D1A",
    marginTop: 10,
  },
  resultsContainer: {
    width: "100%",
    // backgroundColor: "#4F879D1A",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "20px 10px",
  },
  resultsWrapper: {
    marginRight: 5,
  },
  nameClass: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  classWrap: {
    height: 20,
    width: 20,
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  classA: {
    backgroundColor: "#00A651",
  },
  classB: {
    backgroundColor: "#8CC63F",
  },
  classC: {
    backgroundColor: "#FFD500",
  },
  classD: {
    backgroundColor: "#EE1D23",
  },
  lucht: {
    marginRight: 13,
  },
  winter: {
    marginRight: 20,
  },
  zomer: {
    marginRight: 23,
  },
  classLetter: {
    color: "#ffffff",
    fontSize: 12,
  },
  name: {
    color: "#2E276A",
    fontSize: 8,
    marginRight: 5,
  },
  roomInfoWraper: {
    marginTop: 10,
    marginLeft: 20,
  },
  roomInfoLabel: {
    color: "#009FDF",
    fontSize: 8,
  },
  roomInfoText: {
    fontSize: 8,
    marginTop: 3,
  },
  unitText: {
    fontSize: 8,
    fontWeight: "normal",
  },
  uur: {
    marginLeft: 3,
  },
})

export default ResultsPage
