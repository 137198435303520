import React from "react"
import Modal from "react-modal"

const ModalButton = ({ text, onClick, customStyle, customTextStyle }) => (
  <div
    className={`w-48 flex items-center justify-center py-3 px-2 cursor-pointer bg-darkBlue rounded-lg ${customStyle}`}
    onClick={onClick}
  >
    <div className="flex items-center">
      <p className={`text-white leading-none py-1 ${customTextStyle}`}>
        {text}
      </p>
    </div>
  </div>
)

const ConfirmModal = ({ open, closeModal, onReset, onClose, title }) => {
  return (
    <Modal
      isOpen={open}
      className="bg-white z-20 border-0 w-11/12 md:w-2/4 laptop:h-auto rounded-lg overflow-y-auto md:overflow-hidden"
      onRequestClose={closeModal}
      ariaHideApp={false}
      overlayClassName="Overlay"
    >
      <div className="h-full px-16 py-12 w-auto flex-col inline-block laptop:flex relative rounded-lg">
        <p className="font-mulish font-bold text-h2 text-darkBlue mb-10">
          {title}
        </p>
        <div className="flex flex-col laptop:flex-row">
          <ModalButton
            text="Resetten"
            onClick={() => {
              onReset()
            }}
          />
          <ModalButton
            text="Annuleren"
            onClick={() => {
              onClose()
            }}
            customStyle="bg-white border-2 border-gray mt-2 laptop:ml-5 laptop:mt-0"
            customTextStyle="text-greyWarning"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
