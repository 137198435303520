import React from "react"

const Button = ({
  isDark,
  text,
  icon,
  onClick,
  disabled,
  btnStyling,
  iconStyle,
  pdfDownloadButton,
  textSize = "",
}) => {
  return (
    <div
      className={`${
        !btnStyling
          ? "w-full flex items-center justify-center py-3 px-2 cursor-pointer"
          : btnStyling
      } ${isDark ? "bg-darkBlue" : "bg-lightBlue"} ${
        disabled && "bg-buttonGray"
      }`}
      onClick={onClick}
    >
      <div
        className={`flex place-items-center mx-auto ${
          pdfDownloadButton && " justify-start "
        }`}
      >
        {icon && (
          <img
            src={icon}
            alt="icon"
            className={`${!iconStyle ? "h-4 w-4" : iconStyle}`}
          />
        )}
        <p
          className={`text-white ml-2 text-center leading-none py-1 ${textSize}`}
        >
          {text}
        </p>
      </div>
    </div>
  )
}

export default Button
