import { createStore, combineReducers } from "redux"

import projectDetails from "./reducers/ProjectDetailsReducer"
import roomsReducer from "./reducers/RoomsReducer"
import activeRoom from "./reducers/activeRoom"
import activeTool from "./reducers/activeTool"
import toggleError from "./reducers/errorReducer"
import toggleIsCalculationDone from "./reducers/calculationDoneReducer"

const rootReducer = combineReducers({
  data: projectDetails,
  rooms: roomsReducer,
  activeRoom: activeRoom,
  error: toggleError,
  isCalculationDone: toggleIsCalculationDone,
  activeTool: activeTool,
})

const store = createStore(rootReducer)

export default store
