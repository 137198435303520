import React from "react"

const HeaderFooterStripes = ({ small }) => {
  return (
    <div className="w-full">
      <div className="relative">
        {!small && (
          <p
            className="bottom-0 left-1/3 my-2 absolute text-gray text-xs"
            style={{ fontSize: "12px", marginLeft: "14rem" }}
          >
            Deze tool is ontwikkeld door{" "}
            <a
              href="https://renor.nl/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 underline-none"
            >
              Renor
            </a>{" "}
          </p>
        )}
      </div>
      <div className={`${small ? "h-3" : "h-10"} bg-lightBlue`}></div>
      <div className={`${small ? "h-3" : "h-10"} bg-darkBlue`}></div>
    </div>
  )
}

export default HeaderFooterStripes
