import React from "react"

import { View, Text, StyleSheet, Page, Font } from "@react-pdf/renderer"
import font from "fonts/Mulish-Bold.ttf"

const bullets = [
  "Het vertrek is gelegen op een bouwlaag tussen de begane grond en de dakverdieping in",
  "Minimale Rc-waardes conform Bouwbesluit 2012",
  "Rondom het glas in de gevel is 10% kozijn aanwezig met een U-waarde van 2,4 W/(m².K)",
  "Buitenzonwering schakelt bij een zonintensiteit van 250 W/m²",
  "De gevel kent een qv,10-waarde van 0,2 tot 0,4 dm3/s.m²",
  "Het gebouw is 9 m hoog",
  "Opwarmtoeslag = 5 W/m² vloeroppervlak",
]

export const Classes = ({ className }) => {
  switch (className) {
    case "A":
      return (
        <View style={[styles.classWrap, styles.classA]}>
          <Text style={styles.classLetter}>A</Text>
        </View>
      )
    case "B":
      return (
        <View style={[styles.classWrap, styles.classB]}>
          <Text style={styles.classLetter}>B</Text>
        </View>
      )
    case "C":
      return (
        <View style={[styles.classWrap, styles.classC]}>
          <Text style={styles.classLetter}>C</Text>
        </View>
      )
    case "D":
      return (
        <View style={[styles.classWrap, styles.classD]}>
          <Text style={styles.classLetter}>D</Text>
        </View>
      )
    default:
      return <View>No class</View>
  }
}

const Header = () => (
  <View style={styles.header}>
    <View>
      <Text style={styles.headlines}>Binnenklimaatcheck</Text>
      <Text style={styles.headlineDescription}>
        Controleer het binnenklimaat van jouw ruimte
      </Text>
    </View>
  </View>
)

const Footer = () => (
  <View style={styles.footer}>
    <View style={[styles.footerStripe, styles.lightStripe]}></View>
    <View style={[styles.footerStripe, styles.darkStripe]}></View>
  </View>
)

Font.register({ family: "Mulish-Bold", src: font })

const LastPage = () => (
  <Page style={styles.body}>
    <Text style={styles.headline}>Toelichting klimaatklasse</Text>
    <View style={styles.explanationWrapper}>
      <Text style={styles.explanationText}>
        In het PvE Gezonde Kantoren wordt gewerkt met drie kwaliteitsniveaus, te
        weten Klasse C (voldoende), Klasse B (goed) en Klasse A (zeer goed).
        Klasse C is hierbij het basisniveau; vaak gebaseerd op geldende wet- en
        regelgeving, zoals vastgelegd in het Bouwbesluit 2012. Indien niet
        voldaan wordt aan Klasse C, wordt in deze tool automatisch een Klasse D
        toegekend. De prestatie eisen per klimaatklasse zijn terug te vinden in
        het PvE Gezonde Kantoren 2021.
      </Text>
      <View style={styles.classes}>
        <Classes className="A" />
        <Classes className="B" />
        <Classes className="C" />
        <Classes className="D" />
      </View>
    </View>

    <View>
      <Text style={styles.headline}>Aandachtspunten resultaten</Text>
      <Text style={styles.subHeadline}>
        <Text style={styles.asterix}>|</Text>{" "}
        <Text>Voorwaarden regelbaarheid ruimtetemperatuur</Text>
      </Text>
      <Text style={[styles.lastPageText, styles.fistParagraph]}>
        Als voorwaarde voor Wintercomfort klasse B en Zomercomfort klasse A is
        in het PvE Gezonde Kantoren 2021 een regelsnelheid van minimaal 1 ºC per
        half uur na verstelling gesteld. Met het klimaatconcept
        ‘Vloerverwarming/-koeling’ kan doorgaans niet aan deze eis worden
        voldaan, omdat de warmtecapaciteit van de vloer de reactiesnelheid
        beperkt.
      </Text>
      <Text style={[styles.lastPageText, styles.andaText]}>
        Als voorwaarde voor Wintercomfort klasse B is in het PvE Gezonde
        Kantoren 2021 een temperatuursensor in de bedienunit met aantoonbare
        regelnauwkeurigheid +/- 1 ºC gesteld. Met het klimaatconcept ‘Radiatoren
        + topkoeling’ kan doorgaans niet aan deze eis worden voldaan, omdat de
        thermostaatkraan van de radiator een groter onnauwkeurigheid kent.
      </Text>
      <Text style={[styles.lastPageText, styles.andaText]}>
        Als voorwaarde voor Zomercomfort klasse B is in het PvE Gezonde Kantoren
        2021 een handmatige naregeling voor koeling op vertrekniveau omschreven.
        Het klimaatconcept ‘Radiatoren + topkoeling’ kent geen handmatige
        naregeling voor koeling op vertrekniveau, waardoor met dit concept niet
        aan deze voorwaarde kan worden voldaan.
      </Text>

      <Text style={[styles.subHeadline, styles.secondSubHeadline]}>
        <Text style={styles.asterix}>||</Text>{" "}
        <Text>Maximale ventilatievoud per klimaatconcept</Text>
      </Text>
      <Text style={[styles.lastPageText, styles.lastAndaText]}>
        Per klimaatconcept is in de tool een maximale ventilatievoud ingesteld,
        om een te hoge voelbare luchtstroming in de leefzone te voorkomen. Bij
        overschrijding van deze maximale ventilatievoud wordt bij de keuze van
        het klimaatconcept de volgende waarschuwing afgegeven: “Let op:
        ventilatievoud is ongebruikelijk hoog voor dit systeem”.
      </Text>
    </View>

    <Text style={styles.headline}>Over de berekening</Text>
    <Text style={styles.lastPageText}>
      Bij de resultaten voor ‘luchtverversing’ zijn de uitgangspunten zoals
      vermeld in het PvE Gezonde Kantoren 2021 van toepassing. Dit betekent o.a.
      dat er wordt uitgegaan van een gemiddeld metabolisme van de gebruikers en
      een ventilatie-efficiëntie van minimaal 0,8.
    </Text>
    <Text style={styles.lastPageText}>
      Aan de resultaten voor ‘wintercomfort’ liggen standaard
      warmteverliesberekingen ten grondslag. Deze berekeningen zijn gebaseerd op
      de berekeningsmethodiek uit ISSO publicatie 53.
    </Text>
    <Text style={styles.lastPageText}>
      Aan de resultaten voor ‘zomercomfort’ liggen standaard koellastberekingen
      ten grondslag. Deze berekeningen zijn gebaseerd op de berekeningsmethodiek
      uit NEN 5067. In de berekeningen zijn o.a. de volgende uitgangspunten
      aangehouden:
    </Text>
    <View style={{ marginTop: 10, marginLeft: 10 }}>
      {bullets.map((elem) => (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            padding: 0,
            marginTop: -5,
          }}
        >
          <Text style={{ color: "#2E276A" }}>{"\u2022"}</Text>
          <Text style={{ fontSize: 8, flex: 1, paddingLeft: 5 }}>{elem}</Text>
        </View>
      ))}
    </View>
    <View style={styles.disclamerContainer}>
      <Text style={styles.disclamer}>Disclaimer</Text>
      <Text style={styles.disclamerText}>
        De resultaten in deze tool zijn indicatief en zijn derhalve niet
        geschikt om ontwerpberekeningen van klimaatinstallaties te vervangen. Om
        te kunnen vaststellen of werkelijk aan de gestelde kwaliteits-eisen uit
        het PvE Gezonde Kantoren kan worden voldaan dient de Kwaliteitsborging
        uit het PvE Gezonde Kantoren gevolgd te worden.
      </Text>
      <Header />
    </View>
    <Footer />
  </Page>
)

const styles = StyleSheet.create({
  lastAndaText: {
    marginBottom: 20,
  },
  fistParagraph: {
    marginTop: 3,
  },
  andaText: {
    marginTop: 3,
  },
  secondSubHeadline: {
    marginTop: 10,
  },
  subHeadline: {
    fontSize: 10,
    color: "#000",
    fontFamily: "Mulish-Bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  asterix: {
    fontSize: "8px",
  },
  disclamerContainer: {
    position: "absolute",
    bottom: "50",
    paddingHorizontal: 25,
  },
  header: {
    height: "60px",
    width: "100%",
    borderTop: "1px solid #707070",
    paddingTop: "10px",
    fontFamily: "Mulish-Bold",
  },
  headlines: {
    fontSize: 18,
    color: "#2E276A",
    fontFamily: "Mulish-Bold",
  },
  nl: {
    color: "#009FDF",
  },
  headlineDescription: {
    fontSize: "10px",
    marginTop: "10px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "40px",
  },
  darkStripe: {
    backgroundColor: "#2E276A",
    width: "100%",
    height: "15px",
  },
  lightStripe: {
    width: "100%",
    height: "25px",
    backgroundColor: "#009FDF",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  headline: {
    color: "#2E276A",
    marginBottom: "10px",
    fontFamily: "Mulish-Bold",
  },
  explanationWrapper: {
    width: "100%",
    backgroundColor: "#4F879D1A",
    padding: "25px 20px",
    borderRadius: "3px",
    marginBottom: "20px",
  },
  explanationText: {
    fontSize: "9px",
  },
  classWrap: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    marginRight: "10px",
    marginTop: "20px",
  },
  classLetter: {
    color: "#fff",
  },
  classA: {
    backgroundColor: "#00A651",
  },
  classB: {
    backgroundColor: "#8CC63F",
  },
  classC: {
    backgroundColor: "#FFD500",
  },
  classD: {
    backgroundColor: "#EE1D23",
  },
  disclamer: {
    marginTop: "130px",
    color: "#2E276A",
    fontSize: "12px",
    fontFamily: "Mulish-Bold",
  },
  disclamerText: {
    fontSize: "9px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  lastPageText: {
    fontSize: "8px",
    marginTop: "2px",
  },
  classes: {
    display: "flex",
    flexDirection: "row",
  },
})

export default LastPage
