import React from "react"
import Modal from "react-modal"
import Input from "components/Input"

import { IoCheckmarkSharp } from "react-icons/io5"
import { IoCloseSharp } from "react-icons/io5"

const AddRoomModal = ({
  open,
  closeModal,
  onConfirm,
  value,
  onChange,
  invalid,
}) => {
  // test
  return (
    <Modal
      isOpen={open}
      contentLabel="Example Modal"
      className="bg-white z-20 border-none"
      onRequestClose={closeModal}
      ariaHideApp={false}
      overlayClassName="Overlay"
    >
      <div className="bg-white px-16 py-10 rounded-md">
        <p className="font-bold text-12 md:text-h3 text-darkBlue text-center">
          Vul de naam van de ruimte in
        </p>
        <div className="my-5">
          {/* <input type='text'/> */}
          <Input
            autoFocus={true}
            value={value}
            onChange={onChange}
            invalid={invalid}
          />
        </div>
        <div className="flex items-center justify-center space-x-5 cursor-pointer mt-5">
          <div
            className="flex items-center justify-center bg-red py-1 px-3 cursor-pointer"
            onClick={closeModal}
          >
            <IoCloseSharp className="text-white" size={22} />
            <p className="text-5 md:text-12 text-white font-bold ml-2 leading-none ">
              Annuleren
            </p>
          </div>
          <div className="flex items-center justify-center bg-green py-1 px-1 md:px-3">
            <IoCheckmarkSharp className="text-white" size={22} />
            <p
              className="text-5 md:text-12 text-white font-bold ml-2 leading-none "
              onClick={onConfirm}
            >
              Aanmaken
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddRoomModal
