import React from "react"

const MediumHeadline = ({ text, subtext }) => {
  return (
    <>
      <h3 className="text-darkBlue font-bold text-h2 font-mulish lg:text-28 laptop:text-24 break-words">
        {text}
      </h3>
      <p>{subtext}</p>
    </>
  )
}

export default MediumHeadline
