import Redeljik from "../../assets/images/redeljik.png"

const INITAL_STATE = {
  currPage: 1,
  Projectnaam: "",
  Projectnummer: "",
  Contactpersoon: "",
  Organisatie: "",
  Gebouwnaam: "",
  Kantoor: "Kantoor",
  Gebruiksoppervlakte: "",
  Versienummer: "",
  Naam: "",
  Bedrijf: "",
  Email: "",
  Telefoonnummer: "",
  projectId: "",
  projectAddedToFirebase: false,
  Compactheid: {
    id: 4,
    title: "Redelijk compact",
    subtitle: "Avl / Ag = 1,0",
    image: Redeljik,
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  Luchtdichtheid: {
    id: 2,
    value: "Basis",
    secondaryValue: "qv;10 = 0,6",
    values: {
      ep1: 1.0417,
      ep2: 1.029,
      ep3: 1.0286,
    },
  },
  Glaspercentage: {
    id: 2,
    value: "50%",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  Glastype: {
    id: 2,
    value: "HR",
    secondaryValue: "U = 2,2",
    values: {
      ep1: 1.1111,
      ep2: 1.087,
      ep3: 1.0857,
    },
  },
  "Isolatie schil": {
    id: 2,
    value: "Gemiddeld",
    secondaryValue: "Rc Gevel = 2,5",
    tertiaryValue: "Rc Dak = 2,5",
    values: {
      ep1: 1.0556,
      ep2: 1.0435,
      ep3: 1.0286,
    },
  },
  //BENG gebouw
  BENGCompactheid: {
    id: 4,
    title: "Redelijk compact",
    subtitle: "Avl / Ag = 1,0",
    image: Redeljik,
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  BENGLuchtdichtheid: {
    id: 3,
    value: "Goed",
    secondaryValue: "qv;10 = 0,4",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  BENGGlaspercentage: {
    id: 2,
    value: "50%",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  BENGGlastype: {
    id: 3,
    value: "HR++",
    secondaryValue: "U = 1,7",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  "BENGIsolatie schil": {
    id: 4,
    value: "Nieuwbouw",
    secondaryValue: "Rc Gevel = 4,7",
    tertiaryValue: "Rc Dak = 6,3",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  // end first page BENG defaults
  // third page BENG defaults
  BENGVentilatievoud: {
    id: 3,
    value: "Voldoende",
    secondaryValue: "vv = 2",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  "BENGType LBK": {
    id: 4,
    value: "Wiel / Kruis",
    secondaryValue: "ErP",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  BENGCo2regeling: {
    id: 0,
    text: "Nee",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  BENGBevochtiging: {
    id: 1,
    text: "Nee",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  BENGKoudeopwekking: {
    id: 2,
    text: "Warmtepomp lucht",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  "BENGAanvoer-temperatuur CV": {
    id: 3,
    value: 50,
    values: {
      ep1: null,
      ep2: 0.971,
      ep3: 1.0286,
    },
  },
  BENGNumberButtons: {
    id: 3,
    text: 20,
    values: {
      ep1: null,
      ep2: -29,
      ep3: 29,
    },
  },
  BENGVerlichting: {
    id: 2,
    text: "LED",
    values: {
      ep1: null,
      ep2: {
        inclDagl: 1.0,
        exclDagl: 1.0435,
      },
      ep3: {
        inclDagl: 1.0,
        exclDagl: 0.9429,
      },
    },
  },
  BENGDaglichtregeling: {
    id: 1,
    text: "Ja",
  },
  // end BENG
  Ventilatievoud: {
    id: 3,
    value: "Voldoende",
    secondaryValue: "vv = 2",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  "Type LBK": {
    id: 1,
    value: "Twincoil",
    secondaryValue: "< 2016",
    values: {
      ep1: null,
      ep2: 1.2609,
      ep3: 0.9143,
    },
  },
  Co2regeling: {
    id: 0,
    text: "Nee",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  Bevochtiging: {
    id: 1,
    text: "Nee",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  Koudeopwekking: {
    id: 2,
    text: "Warmtepomp lucht",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  "Aanvoer-temperatuur CV": {
    id: 2,
    value: 55,
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  Verlichting: {
    id: 1,
    text: "TL5",
    values: {
      ep1: null,
      ep2: {
        inclDagl: 1.087,
        exclDagl: 1.1884,
      },
      ep3: {
        inclDagl: 0.8857,
        exclDagl: 0.8,
      },
    },
  },
  Daglichtregeling: {
    id: 0,
    text: "Nee",
  },
  NumberButtons: {
    id: 0,
    text: 0,
    values: {
      ep1: null,
      ep2: 0,
      ep3: 0,
    },
  },
  totaleOpbrengst: null,
}

export default function projectDetails(state = INITAL_STATE, action) {
  switch (action.type) {
    case "FILL_DATA": {
      return { ...action.payload }
    }
    case "ADD_TO_FIREBASE": {
      return {
        ...state,
        projectAddedToFirebase: true,
        projectId: action.payload,
      }
    }
    default: {
      return { ...state }
    }
  }
}
