import { airFlowHeatLoad } from "constants/airFlowHeatLoad"

export const calculateAirFlowHeatLoad = () => {
  return airFlowHeatLoad.interneWarmtelast + airFlowHeatLoad.luchtdebiet
}

export const calculcateLuchtdebiet = (
  ventilatiehoeveelheid,
  totaalVloeroppervlak
) => {
  //We need luchtdebiet so we can later count the options for classes. OptionA, optionB, optionC
  let result = ventilatiehoeveelheid / totaalVloeroppervlak
  airFlowHeatLoad.luchtdebiet = result.toFixed(1)
}

export const calculateInterneWarmtelastSum = (
  interneWarmtelast,
  bezzeting,
  totaalVloeroppervlak
) => {
  //We need interneWarmtelast sum so we can calculate option for cooling

  let result = interneWarmtelast + (bezzeting * 80) / totaalVloeroppervlak
  airFlowHeatLoad.interneWarmtelastSum = result.toFixed(1)
}
