import React from "react"

import { View, Text, Page, StyleSheet, Font } from "@react-pdf/renderer"
import font from "fonts/Mulish-Bold.ttf"

import { Header, Footer, Date } from ".."

Font.register({ family: "Mulish-Bold", src: font })

const calculateRedultsWidth = (str) => {
  if (str.length === 1) return "40px"
  else if (str.length === 3) return "65px"
  else if (str.length > 3) return "80px"
}

const FirstPage = ({ details, results, activeTool }) => (
  <Page style={styles.body}>
    <Header activeTool={activeTool} />
    <View style={styles.projectInfo}>
      <Text style={styles.project}>Gebouw</Text>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Gebouwnaam</Text>
          <Text style={styles.projectData}>{details?.Gebouwnaam}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Kantoor</Text>
          <Text style={styles.projectData}>{details?.Kantoor}</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Versienummer</Text>
          <Text style={styles.projectData}>{details?.Versienummer}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Gebruiksoppervlakte</Text>
          <Text style={styles.projectData}>
            {details?.Gebruiksoppervlakte} m²
          </Text>
        </View>
      </View>
      <Text style={styles.infoHead}>Opgesteld door</Text>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Naam</Text>
          <Text style={styles.projectData}>{details.Naam}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Bedrijf</Text>
          <Text style={styles.projectData}>{details?.Bedrijf}</Text>
        </View>
      </View>
      <View style={styles.infoWrapper}>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>E-mail</Text>
          <Text style={styles.projectData}>{details?.Email}</Text>
        </View>
        <View style={styles.infoWrap}>
          <Text style={styles.projectLabel}>Telefoonnummer</Text>
          <Text style={styles.projectData}>{details?.Telefoonnummer}</Text>
        </View>
      </View>
    </View>
    <View>
      <Text
        style={[styles.headline, { marginLeft: 0, padding: 0, fontSize: 18 }]}
      >
        Resultaten
      </Text>
      <View style={styles.resultatenWrapper}>
        <Text style={[styles.headline, { paddingLeft: 20, paddingTop: 10 }]}>
          {details.Gebouwnaam}
        </Text>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={styles.informationContainer}>
            <View>
              <View style={styles.titleInformationView}>
                <Text style={styles.infomrationTitleText}>BENG 1</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={styles.informationValueText}>{results.Beng1}</Text>
                <Text style={styles.kWhm2Text}>kWh/m²</Text>
              </View>
            </View>
          </View>
          <View style={styles.informationContainer}>
            <View>
              <View style={styles.titleInformationView}>
                <Text style={styles.infomrationTitleText}>BENG 2</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={styles.informationValueText}>{results.Beng2}</Text>
                <Text style={styles.kWhm2Text}>kWh/m²</Text>
              </View>
            </View>
          </View>
          <View style={styles.informationContainer}>
            <View>
              <View style={styles.titleInformationView}>
                <Text style={styles.infomrationTitleText}>BENG 3</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={styles.informationValueText}>{results.Beng3}</Text>
                <Text style={styles.kWhm2Text}>%</Text>
              </View>
            </View>
          </View>
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                styles.titleInformationView,
                {
                  backgroundColor: "#009FDF",
                },
              ]}
            >
              <Text style={[styles.infomrationTitleText, { paddingRight: 20 }]}>
                Energielabel
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={[
                  styles.greenBox,
                  {
                    backgroundColor: results.Energielabel.color,
                    width: calculateRedultsWidth(results.Energielabel.name),
                    clipPath:
                      "polygon(75% 1%, 100% 50%, 75% 100%, 0 100%, 0 0)",
                  },
                ]}
              >
                <Text>{results.Energielabel.name}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    <Date />
    <Footer />
  </Page>
)

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  headlineDescription: {
    fontSize: 10,
  },
  projectInfo: {
    marginTop: "25px",
  },
  project: {
    color: "#2E276A",
    fontFamily: "Mulish-Bold",
    marginBottom: "20px",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
  infoWrap: {
    width: "50%",
  },
  projectLabel: {
    color: "#009FDF",
    fontSize: "10px",
    marginBottom: "8px",
  },
  projectData: {
    fontSize: "10px",
  },
  infoHead: {
    marginTop: "20px",
    marginBottom: "10px",
    color: "#2E276A",
    fontSize: "15px",
    fontFamily: "Mulish-Bold",
  },
  dateWrapper: {
    position: "absolute",
    bottom: "10%",
    left: "35px",
  },
  dateLabel: {
    color: "#009FDF",
    fontSize: "10px",
  },
  date: {
    fontSize: "13px",
    marginTop: "5px",
  },
  asterixSign: {
    fontSize: 5,
    marginLeft: 2,
    color: "#6C6C6C",
  },
  textWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    fontFamily: "Mulish-Bold",
  },
  three: {
    position: "absolute",
    fontSize: 6,
    top: -3,
    left: 10,
  },
  headline: {
    color: "#2E276A",
    fontSize: 14,
    marginBottom: 13,
    fontFamily: "Mulish-Bold",
    padding: "0 10px",
    paddingTop: "15px",
  },
  container: {
    backgroundColor: "#4F879D1A",
    marginTop: 10,
  },
  resultsContainer: {
    width: "100%",
    // backgroundColor: "#4F879D1A",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "20px 10px",
  },
  resultsWrapper: {
    marginRight: 5,
  },
  nameClass: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  classWrap: {
    height: 20,
    width: 20,
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  classA: {
    backgroundColor: "#00A651",
  },
  classB: {
    backgroundColor: "#8CC63F",
  },
  classC: {
    backgroundColor: "#FFD500",
  },
  classD: {
    backgroundColor: "#EE1D23",
  },
  lucht: {
    marginRight: 13,
  },
  winter: {
    marginRight: 20,
  },
  zomer: {
    marginRight: 23,
  },
  classLetter: {
    color: "#ffffff",
    fontSize: 12,
  },
  name: {
    color: "#2E276A",
    fontSize: 8,
    marginRight: 5,
  },
  roomInfoWraper: {
    marginTop: 10,
    marginLeft: 20,
  },
  roomInfoLabel: {
    color: "#009FDF",
    fontSize: 8,
  },
  roomInfoText: {
    fontSize: 8,
    marginTop: 3,
  },
  unitText: {
    fontSize: 8,
    fontWeight: "normal",
  },
  uur: {
    marginLeft: 3,
  },
  resultatenWrapper: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 3,
    borderColor: "rgba(211, 211, 211,1)",
    borderStyle: "solid",
  },
  informationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 40,
    "@media (min-width: 1024px)": {
      padding: 20,
    },
  },
  titleInformationView: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#2E276A",
    // paddingTop: 4,
    // paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
  infomrationTitleText: {
    color: "white",
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 8,
  },
  informationValueText: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#2E276A",
    marginLeft: 8,
    marginTop: 10,
  },
  kWhm2Text: {
    fontSize: 5,
    marginLeft: 8,
    marginTop: 20,
    paddingRight: 20,
  },
  greenBox: {
    // width: 40,
    // height: 40,
    marginTop: 10,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    color: "white",
    fontSize: 18,
  },
})

export default FirstPage
