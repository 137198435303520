import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Input from "components/Input"
import Button from "components/Button"
import ArrowRight from "assets/images/arrow-right.svg"
import { fillData } from "redux/actions"
import Select from "components/Select"
// import ProjectService from "services/project.service"
// import { firebaseAuth } from "firebase-config"
// import { useHistory } from "react-router"
// import { fillData, addProjectToFirebase } from "redux/actions"

const options = [{ value: "option1", label: "Kantoor" }]

const AlgemeenSection = ({
  email,
  setEmail,
  gebruiksoppervlakte,
  setGebruiksoppervlakte,
  onChangeStep,
}) => {
  const data = useSelector((state) => state.data)
  const dispatch = useDispatch()
  // const history = useHistory()

  const [selectedOption, setSelectedOption] = useState(options[0]) // set default value to the first option in the options array
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    dispatch(fillData({ ...data, Kantoor: selectedOption.label }))
  }

  // const addProject = async () => {
  //   try {
  //     if (data.projectAddedToFirebase)
  //       return history.push("/energielabelcheck/ruimtes")
  //     firebaseAuth
  //       .signInAnonymously()
  //       .then(() => {
  //         const response = new ProjectService()
  //           .create({
  //             createdAt: new Date(),
  //             email: data.Email,
  //             createdPDF: false,
  //           })
  //           .then((res) => {
  //             dispatch(addProjectToFirebase(res.id))
  //             history.push("/energielabelcheck/ruimtes")
  //           })
  //           .catch((err) => {
  //             console.log(err)
  //           })
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   } catch (err) {
  //     console.log("NEKI ERROR", err)
  //   }
  // }

  return (
    <div className="mt-8">
      <p className="text-h4 font-bold text-darkBlue pb-3">Gebouw</p>
      <div className="flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5">
        <Input
          placeholder="Gebouwnaam"
          label={"Gebouwnaam"}
          type="text"
          value={data.Gebouwnaam}
          onChange={(e) =>
            dispatch(fillData({ ...data, Gebouwnaam: e.target.value }))
          }
        />
        <Select
          label={"Gebouwfunctie"}
          placeholder={"Kantoor"}
          options={options}
          onChange={handleSelectChange}
          value={selectedOption}
        />
        <Input
          placeholder="Versienummer"
          label={"Versienummer"}
          type="text"
          value={data.Versienummer}
          onChange={(e) =>
            dispatch(fillData({ ...data, Versienummer: e.target.value }))
          }
        />
        <Input
          placeholder="Gebruiksoppervlakte"
          label={"Gebruiksoppervlakte (m²)*"}
          type="number"
          value={data.Gebruiksoppervlakte}
          invalid={!gebruiksoppervlakte}
          onChange={(e) => {
            dispatch(fillData({ ...data, Gebruiksoppervlakte: e.target.value }))
            setGebruiksoppervlakte(true)
          }}
        />
      </div>

      <p className="text-h4 font-bold text-darkBlue pb-3">Opgesteld door</p>
      <div className="flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5">
        <Input
          placeholder="Naam"
          label={"Naam"}
          type="text"
          value={data.Naam}
          onChange={(e) =>
            dispatch(fillData({ ...data, Naam: e.target.value }))
          }
        />
        <Input
          placeholder="Bedrijf"
          label={"Bedrijf"}
          type="text"
          value={data.Bedrijf}
          onChange={(e) =>
            dispatch(fillData({ ...data, Bedrijf: e.target.value }))
          }
        />
        <Input
          placeholder="E-mail"
          label={"E-mail *"}
          type="email"
          value={data.Email}
          disabled={data.projectAddedToFirebase}
          invalid={!email}
          onChange={(e) => {
            dispatch(fillData({ ...data, Email: e.target.value }))
            setEmail(true)
          }}
        />
        <Input
          placeholder="Telefoonnummer"
          label={"Telefoonnummer"}
          type="tel"
          value={data.Telefoonnummer}
          onChange={(e) =>
            dispatch(fillData({ ...data, Telefoonnummer: e.target.value }))
          }
        />
      </div>
      <div className="mt-24 pb-32">
        <Button
          text={"NAAR GEBOUW"}
          icon={ArrowRight}
          onClick={() => {
            onChangeStep(2)
            document
              .getElementById("flowBodySection")
              ?.scrollIntoView({ behavior: "smooth" })
          }}
        />
      </div>
    </div>
  )
}

export default AlgemeenSection
