import React, { useState } from "react"
import Info from "../../assets/images/info.png"
import StepItem from "./StepItem"
import { useSelector } from "react-redux"

const HorizontalStepper = ({
  stepItems,
  topic,
  setInfoModalText,
  setInfoModalTitle,
  setModalOpen,
  text = "",
  setInfoModalImage,
  image,
}) => {
  const [selectedStep, setSelectedStep] = useState(0)
  const currentID = useSelector((state) => state.data[topic]?.id)

  return (
    <div
      style={{
        margin: "50px 0",
        display: "flex",
        width: "100%",
      }}
      className="font-mulish"
    >
      <div className="container flex justify-between items-center w-full min-h-[50px] place-items-center">
        <div className="flex items-center place-items-center">
          <p className="text-h4 font-bold text-darkBlue font-mulish mt-auto mb-auto">
            {topic}
          </p>
          <img
            className="h-5 cursor-pointer ml-2 mt-2"
            src={Info}
            alt=""
            onClick={() => {
              setInfoModalText(text)
              setInfoModalTitle(`${topic}`)
              setModalOpen(true)
              setInfoModalImage(image)
            }}
          />
        </div>

        <div
          className="flex justify-between"
          style={{
            height: 10,
            // marginLeft: "9rem",
            width: "79%",
            backgroundColor: "#fafafa",
          }}
        >
          {stepItems.map((stepItem) => {
            return (
              <StepItem
                key={stepItem.id}
                item={stepItem}
                selectedStep={currentID}
                setSelectedStep={setSelectedStep}
                topic={topic}
                className="z-10"
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HorizontalStepper
