import React from "react"
import { useHistory } from "react-router"

import Container from "components/Container"

import TVVLLogo from "assets/images/tvvl-logo.svg"
import binnenklimaattechiek from "assets/images/binnenklimaattechiek.svg"
import PlatformDuurzameHuisvesting from "assets/images/PlatformDuurzameHuisvesting-Logo.png"

const Header = (props) => {
  const history = useHistory()

  return (
    <div className="">
      {/* SMALL HEADER */}
      <Container>
        <div className="py-5 md:hidden">
          <div>
            <p
              className="font-bold font-mulish text-darkBlue text-h2 cursor-pointer"
              onClick={() => {
                history.push("/")
                window.location.reload(false)
              }}
            >
              Klimaatinstallatiecheck<span className="text-lightBlue">.nl</span>
            </p>
            <p className="text-h5">
              Snel inzicht in de beste systeemkeuze voor jouw ruimte{" "}
            </p>
          </div>
        </div>

        {/* BIG HEADER */}
        <div className="hidden md:block md:py-5 xl:py-10 lg:flex lg:justify-between items-center">
          <div>
            <p
              className="font-bold font-mulish text-darkBlue text-h2 md:text-h1 lg:text-h3 laptop:text-h2 cursor-pointer"
              onClick={() => {
                history.push("/")
                window.location.reload(false)
              }}
            >
              Klimaatinstallatiecheck<span className="text-lightBlue">.nl</span>
            </p>
            <p className="md:text-h4 lg:text-h5 laptop:text-12">
              Snel inzicht in de beste systeemkeuze voor jouw ruimte
            </p>
          </div>
          <div className="flex items-center">
            <img
              src={binnenklimaattechiek}
              alt="logo"
              className="w-64 mr-10 cursor-pointer"
            />
            <img src={TVVLLogo} alt="logo" className="w-32 cursor-pointer" />
            <img
              src={PlatformDuurzameHuisvesting}
              alt="logo"
              className="ml-2 w-20 cursor-pointer"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header
