/* eslint-disable react-hooks/exhaustive-deps */ import React, {
  useState,
  useEffect,
} from "react"

import { useSelector, useDispatch } from "react-redux"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { Redirect } from "react-router"
import { ExclamationIcon } from "@heroicons/react/solid"
import moment from "moment"

import {
  calculcateLuchtdebiet,
  calculateInterneWarmtelastSum,
} from "calculationFunctions/calculateAirflowHeatLoad"
import { powerAvailable } from "calculationFunctions/powerAvailable"
import { calculateClasses } from "calculationFunctions/calculateClasses"
import { addCalculatedClasses } from "redux/actions"

import MediumHeadline from "components/MediumHeadline"
import Button from "components/Button"
import Classes from "components/Classes"
import PDFGenerator from "components/OriginalPDFGenerator"
import LoadingSpinner from "components/LoadingSpinner"

import PDFIcon from "assets/images/pdf-icon.svg"
import { AiFillWarning } from "react-icons/ai"
import { airFlowHeatLoad } from "constants/airFlowHeatLoad"

import ProjectService from "services/project.service"
import { firebaseAuth } from "firebase-config"

const DownloadPDFText = ({ text }) => {
  return (
    <div className="rounded-md bg-yw-50 p-4 text-white text-left flex place-items-center">
      <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      <p className="ml-2 text-yellow-700">
        Download het PDF rapport indien u de resultaten wilt bewaren
      </p>
    </div>
  )
}

const klimaatconcept = [
  { name: "Klimaatplafonds", value: 8 },
  { name: "Plafondinductie-units", value: 2.7 },
  { name: "VAV + naverwarmer", value: 10 },
  { name: "Fancoil-units", value: 4 },
  { name: "Vloerverwarming/-koeling", value: 8 },
  { name: "Radiatoren + topkoeling", value: 8 },
]

const spanClass = "absolute right-12 top-0 text-8 text-greyWarning"
const winterAsterix = "absolute right-10 font-normal text-8 text-greyWarning"
const zomerAsterix = "absolute right-9 font-normal text-8 text-greyWarning"

const Results = () => {
  const projectDetails = useSelector((state) => state.data)
  const results = useSelector((state) => state.rooms.rooms)
  const dispatch = useDispatch()

  const [canGeneratePDF, setCanGeneratePDF] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [redirect, setRedirect] = useState(null)

  const openMailer = () => {
    window.open(
      "mailto:info@tvvl.nl?subject=Contactaanvraag klimaatinstallatiecheck.nl&body="
    )
  }

  const calculateRooms = (result, roomIndex) => {
    airFlowHeatLoad.ventilatiehoeveelheidPerson =
      result.airFlowHeatLoadData.ventilatiehoeveelheidPerson
    calculcateLuchtdebiet(
      result.airFlowHeatLoadData.ventilatiehoeveelheid,
      result.airFlowHeatLoadData.totaalVloeroppervlak
    )
    calculateInterneWarmtelastSum(
      result.airFlowHeatLoadData.interneWarmtelast,
      result.airFlowHeatLoadData.bezzeting,
      result.airFlowHeatLoadData.totaalVloeroppervlak
    )
    let power = powerAvailable(result.climateConcept)

    let classes = calculateClasses(
      {
        heatingClasses: result.heatingClasses.join(""),
        coolingClasses: result.coolingClasses.join(""),
      },
      power
    )
    dispatch(addCalculatedClasses(roomIndex, classes))
    setIsLoading(false)
  }

  const checkForSteps = () => {
    if (results.length === 0) return setIsLoading(false)

    for (let i = 0; i < results.length; i++) {
      if (
        !results[i].stepsDone.first ||
        !results[i].stepsDone.second ||
        !results[i].stepsDone.third
      ) {
        setCanGeneratePDF(false)
        setIsLoading(false)
      } else {
        calculateRooms(results[i], i)
      }
    }
  }

  const updateFirebase = async () => {
    try {
      firebaseAuth
        .signInAnonymously()
        .then(() => {
          new ProjectService().update(projectDetails.projectId, {
            createdPDF: true,
          })
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  const checkIfValueIsTooHigh = (
    index,
    concept,
    ventilatiehoeveelheid,
    totaalVloeroppervlak
  ) => {
    //First we find the concept
    let localConcept = klimaatconcept.find((value) => concept === value.name)

    //We need to calculate the value from given formula
    let calucaltedValue = (
      ventilatiehoeveelheid /
      totaalVloeroppervlak /
      2.7
    ).toFixed(2)

    //Then we check if calculated value is too high
    return calucaltedValue > localConcept.value
  }

  useEffect(() => {
    if (projectDetails.Email === "")
      return setRedirect(<Redirect to="/wizard/projectgegevens" />)
    checkForSteps()
  }, [])
  return isLoading ? (
    <div className="h-full w-full flex items-center justify-center mt-44">
      <LoadingSpinner />
      {redirect}
    </div>
  ) : (
    <div className="mt-10">
      <MediumHeadline
        text={`${
          projectDetails.Projectnaam
            ? "Resultaten: " + projectDetails.Projectnaam
            : "Resultaten"
        }`}
        subtext="Op basis van de invoergegevens is een berekening voor de
        luchtverversing, het wintercomfort en het zomercomfort uitgevoerd.
        Vervolgens is o.b.v. het PvE Gezonde Kantoren de bijbehorende
        klimaatklasse bepaald. De resultaten vindt u in onderstaande tabel.
        In het PDF rapport vindt u een compleet overzicht van de ingevoerde
        gegevens, de resultaten en bijbehorende toelichting."
      />
      <div className="lg:flex gap-5 mt-5">
        <div className="flex flex-col justify-between w-full">
          {/* <p className="text-h5 md:text-base pt-5 pb-10 inline-block items-center">
            Op basis van de invoergegevens is een berekening voor de
            luchtverversing, het wintercomfort en het zomercomfort uitgevoerd.
            Vervolgens is o.b.v. het PvE Gezonde Kantoren de bijbehorende
            klimaatklasse bepaald. De resultaten vindt u in onderstaande tabel.
            In het PDF rapport vindt u een compleet overzicht van de ingevoerde
            gegevens, de resultaten en bijbehorende toelichting.
          </p> */}
          <DownloadPDFText text="Download het PDF rapport indien u de resultaten wilt bewaren" />

          {!canGeneratePDF ? (
            <div
              className="bg-red-100 border-red-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md w-auto inline-block"
              role="alert"
            >
              <div className="flex px-1 md:w-4/5">
                <div className="py-1">
                  <svg
                    className="fill-current h-6 w-6 text-red-500 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold">
                    Sorry, we kunnen geen PDF genereren.
                  </p>
                  <p className="text-sm">
                    Er ontbreken nog gegevens op één of meerdere ruimtes
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <PDFDownloadLink
              document={
                <PDFGenerator results={results} details={projectDetails} />
              }
              fileName={`binnenklimaatcheck${
                projectDetails.Projectnaam
                  ? "_" + projectDetails.Projectnaam
                  : ""
              }_${moment().format("DDMMYYYY")}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <div className="">
                    <Button isDark text="LOADING" icon={PDFIcon} />
                  </div>
                ) : (
                  <div className="">
                    <Button
                      isDark
                      onClick={updateFirebase}
                      text="DOWNLOAD EEN PDF RAPPORT VAN DE RESULTATEN"
                      icon={PDFIcon}
                    />
                  </div>
                )
              }
            </PDFDownloadLink>
          )}
        </div>
        <div className="bg-blueGrayOpacity rounded-lg p-5 w-1/3 h-1/2">
          <p className="font-bold text-darkBlue mb-5">
            Heb je een vraag of suggestie?
          </p>
          <p className="pt-3 text-h6 md:text-base">
            Schroom niet en neem contact met ons op. Stuur een email naar:{" "}
            <span
              className="font-bold text-lightBlue cursor-pointer"
              onClick={openMailer}
            >
              info@tvvl.nl
            </span>
          </p>
        </div>
      </div>
      <div className="mt-16 mb-10 bg-blueGrayOpacity rounded-lg p-10 overflow-auto w-full">
        <table className="items-center bg-transparent">
          <thead>
            <tr>
              <th className="align-middle py-3 text-xs uppercase whitespace-nowrap text-left text-darkBlue w-2/3"></th>
              <th className="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap text-left text-darkBlue">
                LUCHTVERVERSING
              </th>
              <th className="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap text-left text-darkBlue">
                WINTERCOMFORT
              </th>
              <th className="px-6 align-middle py-3 text-xs uppercase whitespace-nowrap text-left text-darkBlue">
                ZOMERCOMFORT
              </th>
            </tr>
          </thead>
          <tbody>
            {/*eslint-disable-line */}
            {results.length > 0 &&
              results.map((elem, index) =>
                !elem.stepsDone.first ||
                !elem.stepsDone.second ||
                !elem.stepsDone.third ? (
                  <tr
                    className="border-b border-blackBorder relative"
                    key={index}
                  >
                    <td className="py-6 text-left text-darkBlue text-h5 md:text-h4">
                      {elem.name}
                    </td>

                    <td className="flex justify-center items-center px-6 bg-blueGrayOpacity absolute rounded-lg  ml-16 mt-5 h-10 warning">
                      <AiFillWarning color="#ED7105" size={23} />
                      <p className="text-greyWarning pl-2 font-mulish text-h5">
                        Invoergegevens onvolledig
                      </p>
                    </td>
                  </tr>
                ) : (
                  <tr className="border-b border-blackBorder" key={index}>
                    <td className="py-6 text-left text-darkBlue text-h5 md:text-h4">
                      {elem.name}
                    </td>
                    <td className="px-6">
                      <div className="flex justify-center relative">
                        <Classes
                          className={elem.calculatedClasses.luchtkwaliteit}
                        />
                        {checkIfValueIsTooHigh(
                          index,
                          elem?.climateConcept,
                          elem?.airFlowHeatLoadData?.ventilatiehoeveelheid,
                          elem.airFlowHeatLoadData.totaalVloeroppervlak
                        ) && <span className={spanClass}>||</span>}
                      </div>
                    </td>
                    <td className="px-6 whitespace-nowrap">
                      <div className="flex justify-center relative">
                        <Classes
                          className={elem.calculatedClasses.wintercomfort}
                        />
                        {elem?.climateConcept === "Radiatoren + topkoeling" && (
                          <span className={winterAsterix}>|</span>
                        )}
                        {elem?.climateConcept ===
                          "Vloerverwarming/-koeling" && (
                          <span className={winterAsterix}>|</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 whitespace-nowrap">
                      <div className="flex justify-center relative">
                        <Classes
                          className={elem.calculatedClasses.zomercomfort}
                        />
                        {elem?.climateConcept === "Radiatoren + topkoeling" && (
                          <span className={zomerAsterix}>|</span>
                        )}
                        {elem?.climateConcept ===
                          "Vloerverwarming/-koeling" && (
                          <span className={zomerAsterix}>|</span>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
      <div className="w-9/12 pb-24">
        <p className="text-darkBlue text-h3 font-bold font-mulish lg:text-28 laptop:text-24">
          Aandachtspunten resultaten
        </p>
        <p className="mt-5 font-bold font-mulish flex items-center space-x-2">
          <span className="text-8">|</span>{" "}
          <span>Voorwaarden regelbaarheid ruimtetemperatuur</span>
        </p>
        <p className="font-mulish text-h5 mt-3">
          Als voorwaarde voor Wintercomfort klasse B en Zomercomfort klasse A is
          in het PvE Gezonde Kantoren 2021 een regelsnelheid van minimaal 1 ºC
          per half uur na verstelling gesteld. Met het klimaatconcept
          ‘Vloerverwarming/-koeling’ kan doorgaans niet aan deze eis worden
          voldaan, omdat de warmtecapaciteit van de vloer de reactiesnelheid
          beperkt.
        </p>
        <p className="font-mulish text-h5 mt-3">
          Als voorwaarde voor Wintercomfort klasse B is in het PvE Gezonde
          Kantoren 2021 een temperatuursensor in de bedienunit met aantoonbare
          regelnauwkeurigheid +/- 1 ºC gesteld. Met het klimaatconcept
          ‘Radiatoren + topkoeling’ kan doorgaans niet aan deze eis worden
          voldaan, omdat de thermostaatkraan van de radiator een groter
          onnauwkeurigheid kent.
        </p>
        <p className="font-mulish text-h5 mt-3">
          Als voorwaarde voor Zomercomfort klasse B is in het PvE Gezonde
          Kantoren 2021 een handmatige naregeling voor koeling op vertrekniveau
          omschreven. Het klimaatconcept ‘Radiatoren + topkoeling’ kent geen
          handmatige naregeling voor koeling op vertrekniveau, waardoor met dit
          concept niet aan deze voorwaarde kan worden voldaan.
        </p>

        <p className="mt-5 font-bold font-mulish flex items-center space-x-2">
          <span className="text-8">||</span>{" "}
          <span>Maximale ventilatievoud per klimaatconcept</span>
        </p>
        <p className="font-mulish text-h5 mt-3">
          Per klimaatconcept is in de tool een maximale ventilatievoud
          ingesteld, om een te hoge voelbare luchtstroming in de leefzone te
          voorkomen. Bij overschrijding van deze maximale ventilatievoud wordt
          bij de keuze van het klimaatconcept de volgende waarschuwing
          afgegeven: “Let op: ventilatievoud is ongebruikelijk hoog voor dit
          systeem”.
        </p>
        <div className="mt-5 mb-3">
          <h3 className="text-darkBlue font-bold font-mulish text-h4">
            Disclaimer:
          </h3>
          <p className="font-mulish text-h5 mt-3">
            De resultaten in deze tool zijn indicatief en zijn derhalve niet
            geschikt om energieprestatieberekeningen van kantoorgebouwen te
            vervangen. Om te kunnen vaststellen of werkelijk aan de wettelijke
            kaders voor energieprestatie wordt voldaan, dient een volledige
            berekening volgens de NTA 8800 te worden uitgevoerd.{" "}
          </p>
        </div>
      </div>
      <div className="relative">
        <p
          className="bottom-0 right-0 absolute ml-20 mb-4 text-gray text-xs"
          style={{ fontSize: "12px" }}
        >
          Deze tool is ontwikkeld door{" "}
          <a
            href="https://renor.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline-none"
          >
            Renor
          </a>{" "}
        </p>
      </div>
    </div>
  )
}

export default Results
