import React from "react"

import InfoIcon from "assets/images/info.png"
import Tooltip from "components/Tooltip"

const Input = ({
  placeholder,
  label,
  value,
  onChange,
  type,
  disabled,
  inputExp,
  info,
  infoText,
  invalid,
  autoFocus,
}) => {
  return (
    <div className="w-full mt-5 md:mt-0 relative max-w-lg">
      <label className="text-lightBlue pb-1 text-sm flex items-center">
        {label}
      </label>
      {info && (
        <img
          src={InfoIcon}
          data-tip={infoText}
          className="cursor-pointer absolute -top-1 right-0 h-5"
          alt="info"
        />
      )}
      <Tooltip />
      <input
        autoFocus={autoFocus}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        className={`${
          invalid ? "border border-red" : "border border-borderColor"
        } w-full  text-h5 py-2 md:py-2 px-2 rounded-md focus:border focus:border-lightBlue focus:outline-none`}
      />
      {inputExp && (
        <p className="absolute top-9 right-5 text-inputExp text-h5">
          {inputExp}
        </p>
      )}
    </div>
  )
}

export default Input
