import React from "react"

const Stepper = ({ step, onChangeStep }) => {
  return (
    <div className="pb-5 pl-5 pr-8 mt-10">
      <div className="mb-4 pb-4">
        <div className="flex items-center">
          <div
            className="flex items-center relative cursor-pointer"
            onClick={() => onChangeStep(1)}
          >
            <div
              className={`rounded-full h-12 w-12 py-3 ${
                step === 1 || step >= 1 ? "bg-lightBlue" : "bg-gray"
              }`}
            >
              <p className="text-white font-bold text-center text-h4">1</p>
            </div>
            <div
              className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold uppercase ${
                step === 1 || step >= 1 ? "text-lightBlue" : "text-gray"
              } `}
            >
              Algemeen
            </div>
          </div>
          <div
            className={`flex-auto border-t-4 transition duration-500 ease-in-out ${
              step === 2 || step > 1 ? "border-lightBlue" : "border-gray"
            }`}
          ></div>
          <div
            className="flex items-center text-gray-500 relative cursor-pointer"
            onClick={() => onChangeStep(2)}
          >
            <div
              className={`rounded-full h-12 w-12 py-3 ${
                step === 2 || step > 1 ? "bg-lightBlue" : "bg-gray"
              }`}
            >
              <p className="text-white font-bold text-center text-h4">2</p>
            </div>
            <div
              className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold uppercase ${
                step === 2 || step > 1 ? "text-lightBlue" : "text-gray"
              } `}
            >
              Gebouw
            </div>
          </div>
          <div
            className={`flex-auto border-t-4 transition duration-500 ease-in-out ${
              step === 3 ? "border-lightBlue" : "border-gray"
            }`}
          ></div>
          <div
            className="flex items-center text-gray-500 relative cursor-pointer"
            onClick={() => onChangeStep(3)}
          >
            <div
              className={`rounded-full h-12 w-12 py-3 ${
                step === 3 ? "bg-lightBlue" : "bg-gray"
              }`}
            >
              <p className="text-white font-bold text-center text-h4">3</p>
            </div>
            <div
              className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold uppercase ${
                step === 3 ? "text-lightBlue" : "text-gray"
              } `}
            >
              Installaties
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stepper
