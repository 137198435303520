const INITAL_STATE = {
  activeTool: "",
  navigaiton: "/",
  subtitle: "",
  toolId: null,
}

export default function activeTool(state = INITAL_STATE, action) {
  switch (action.type) {
    case "CHANGE_ACTIVE_TOOL": {
      return {
        ...state,
        activeTool: action.toolName,
        navigaiton: action.toolNavigaiton,
        subtitle: action.toolSubtitle,
        toolId: action.toolId,
      }
    }
    default: {
      return { ...state }
    }
  }
}
