import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router"

import MediumHeadline from "components/MediumHeadline"
import Input from "components/Input"
import Button from "components/Button"

import ArrowRight from "assets/images/arrow-right.svg"

import { fillData, addProjectToFirebase } from "redux/actions"
import ErrorAlert from "components/ErrorAlert"

import ProjectService from "../services/project.service"
import { firebaseAuth } from "firebase-config"
import { changeActiveTool } from "redux/actions"

const Projectgegevens = ({ setPage }) => {
  const [email, setEmail] = useState(true)
  const dispatch = useDispatch()
  const activeToolReduxObject = useSelector((state) => state.activeTool)
  const data = useSelector((state) => state.data)
  const history = useHistory()

  const checkForEmail = () => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (data.Email === "" || !regEmail.test(data.Email)) return setEmailError()
    return addProject()
  }

  const setEmailError = () => {
    setEmail(false)
  }

  useEffect(() => {
    dispatch(
      changeActiveTool(
        "Binnenklimaatcheck",
        "/",
        "Controleer het binnenklimaat van jouw ruimte",
        1
      )
    )
  }, [])

  const addProject = async () => {
    try {
      if (data.projectAddedToFirebase)
        return history.push("/binnenklimaat/ruimtes")
      firebaseAuth
        .signInAnonymously()
        .then(() => {
          const response = new ProjectService()
            .create({
              createdAt: new Date(),
              email: data.Email,
              createdPDF: false,
              name: "Binnenklimaat",
              toolId: 1,
            })
            .then((res) => {
              dispatch(addProjectToFirebase(res.id))
              history.push("/binnenklimaat/ruimtes")
            })
            .catch((err) => {
              console.error(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  return (
    <div className="relative laptop:pr-40 xl:pr-96 pt-10 overflow-x-none">
      <MediumHeadline text="Projectgegevens" />
      <p className="text-h5 md:text-base pt-5">
        Vul hier de projectgegevens van het kantoorgebouw in. Deze gegevens
        worden gebruikt bij het genereren van het rapport met resultaten.
      </p>

      <ErrorAlert open={!email} />

      <div className="mt-8">
        <div className="flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5">
          <Input
            placeholder="Projectnaam"
            label={"Projectnaam"}
            type="text"
            value={data.Projectnaam}
            onChange={(e) =>
              dispatch(fillData({ ...data, Projectnaam: e.target.value }))
            }
          />
          <Input
            placeholder="Projectnummer"
            label={"Projectnummer"}
            type="text"
            value={data.Projectnummer}
            onChange={(e) =>
              dispatch(fillData({ ...data, Projectnummer: e.target.value }))
            }
          />
        </div>
        <p className="text-h4 font-bold text-darkBlue pb-3">Opgesteld voor</p>
        <div className="flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5">
          <Input
            placeholder="Contactpersoon"
            label={"Contactpersoon"}
            type="text"
            value={data.Contactpersoon}
            onChange={(e) =>
              dispatch(fillData({ ...data, Contactpersoon: e.target.value }))
            }
          />
          <Input
            placeholder="Organisatie"
            label={"Organisatie"}
            type="text"
            value={data.Organisatie}
            onChange={(e) =>
              dispatch(fillData({ ...data, Organisatie: e.target.value }))
            }
          />
        </div>
        <p className="text-h4 font-bold text-darkBlue pb-3">Opgesteld door</p>
        <div className="flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5">
          <Input
            placeholder="Naam"
            label={"Naam"}
            type="text"
            value={data.Naam}
            onChange={(e) =>
              dispatch(fillData({ ...data, Naam: e.target.value }))
            }
          />
          <Input
            placeholder="Bedrijf"
            label={"Bedrijf"}
            type="text"
            value={data.Bedrijf}
            onChange={(e) =>
              dispatch(fillData({ ...data, Bedrijf: e.target.value }))
            }
          />
          <Input
            placeholder="E-mail"
            label={"E-mail *"}
            type="email"
            value={data.Email}
            disabled={data.projectAddedToFirebase}
            invalid={!email}
            onChange={(e) => {
              dispatch(fillData({ ...data, Email: e.target.value }))
              setEmail(true)
            }}
          />
          <Input
            placeholder="Telefoonnummer"
            label={"Telefoonnummer"}
            type="tel"
            value={data.Telefoonnummer}
            onChange={(e) =>
              dispatch(fillData({ ...data, Telefoonnummer: e.target.value }))
            }
          />
        </div>
        <div className="mt-8 pb-32">
          <Button
            text={"MAAK EEN RUIMTE AAN"}
            icon={ArrowRight}
            onClick={checkForEmail}
          />
        </div>
      </div>
      <div className="relative">
        <p
          className="bottom-0 right-0 absolute mb-4 text-gray text-xs"
          style={{ fontSize: "12px" }}
        >
          Deze tool is ontwikkeld door{" "}
          <a
            href="https://renor.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline-none"
          >
            Renor
          </a>{" "}
        </p>
      </div>
    </div>
  )
}

export default Projectgegevens
