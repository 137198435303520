const INITAL_STATE = {
  activeRoom: "",
  index: 0,
}

export default function activeRoom(state = INITAL_STATE, action) {
  switch (action.type) {
    case "CHANGE_ACTIVE_ROOM": {
      return { ...state, activeRoom: action.roomName, index: action.index }
    }
    default: {
      return { ...state }
    }
  }
}
