import React from "react"

import {
  Document,
  View,
  StyleSheet,
  Text,
  Font,
  Image,
} from "@react-pdf/renderer"
import moment from "moment"

import logoSection from "assets/images/logoSection.png"

import FirstPage from "./pages/FirstPage"
import LastPage from "./pages/LastPage"
import ResultsPage from "./pages/ResultsPage"
import font from "fonts/Mulish-Bold.ttf"

export const Header = ({ activeTool }) => (
  <View style={styles.header}>
    <View>
      <Text style={styles.headline}>
        {activeTool !== undefined
          ? activeTool?.activeTool
          : "Energielabelcheck"}
      </Text>
      <Text style={styles.headlineDescription}>
        {activeTool !== undefined
          ? activeTool?.subtitle
          : "Bereken snel een indicatief energielabel"}
      </Text>
    </View>
    <View>
      <Image src={logoSection} style={styles.image} />
    </View>
  </View>
)

export const Footer = () => (
  <View style={styles.footer} fixed>
    <View style={[styles.footerStripe, styles.lightStripe]}></View>
    <View style={[styles.footerStripe, styles.darkStripe]}></View>
  </View>
)

export const Date = () => (
  <View style={styles.dateWrapper}>
    <Text style={styles.dateLabel}>Datum van download</Text>
    <Text style={styles.date}>{moment().format("DD-MM-YYYY")}</Text>
  </View>
)

const PDFGenerator = ({ results, details, activeTool }) => {
  return (
    <Document>
      <FirstPage
        details={details}
        results={results}
        activeTool={activeTool}
        buildingName={details.Gebouwnaam}
      />
      {/* <ResultsPage results={results} buildingName={details.Gebouwnaam} /> */}
      <LastPage details={details} activeTool={activeTool} />
    </Document>
  )
}

Font.register({ family: "Mulish-Bold", src: font })

const styles = StyleSheet.create({
  header: {
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #707070",
  },
  headline: {
    fontSize: 18,
    fontWeight: 900,
    color: "#2E276A",
    fontFamily: "Mulish-Bold",
  },
  nl: {
    color: "#009FDF",
  },
  headlineDescription: {
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "40px",
  },
  logoSeciton: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "230px",
  },
  darkStripe: {
    backgroundColor: "#2E276A",
    width: "100%",
    height: "15px",
  },
  lightStripe: {
    width: "100%",
    height: "25px",
    backgroundColor: "#009FDF",
  },
  dateWrapper: {
    position: "absolute",
    bottom: "10%",
    left: "35px",
  },
  dateLabel: {
    color: "#009FDF",
    fontSize: "10px",
  },
  date: {
    fontSize: "8px",
    marginTop: "5px",
  },
})

export default PDFGenerator
