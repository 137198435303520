import React from "react"

import { View, Text, StyleSheet, Page, Font, Image } from "@react-pdf/renderer"
import font from "fonts/Mulish-Bold.ttf"

// import Zeercompact from "../../../assets/images/zeercompact.png"
// import Redeljik from "../../../assets/images/redeljik.png"
// import Gemiddeld from "../../../assets/images/gemiddeld.png"
// import Minder from "../../../assets/images/minder.png"
// import Niet from "../../../assets/images/niet.png"

// const bullets = [
//   "Het vertrek is gelegen op een bouwlaag tussen de begane grond en de dakverdieping in",
//   "Minimale Rc-waardes conform Bouwbesluit 2012",
//   "Rondom het glas in de gevel is 10% kozijn aanwezig met een U-waarde van 2,4 W/(m².K)",
//   "Buitenzonwering schakelt bij een zonintensiteit van 250 W/m²",
//   "De gevel kent een qv,10-waarde van 0,2 tot 0,4 dm3/s.m²",
//   "Het gebouw is 9 m hoog",
//   "Opwarmtoeslag = 5 W/m² vloeroppervlak",
// ]

const Header = ({ activeTool }) => (
  <View style={styles.header}>
    <View>
      <Text style={styles.headlines}>
        {activeTool !== undefined
          ? activeTool?.activeTool
          : "Energielabelcheck"}
      </Text>
      {/* <Text style={styles.headlineDescription}>
        Maak snel een indicatieve BENG berekening
      </Text> */}
    </View>
  </View>
)

const Footer = () => (
  <View style={styles.footer}>
    <View style={[styles.footerStripe, styles.lightStripe]}></View>
    <View style={[styles.footerStripe, styles.darkStripe]}></View>
  </View>
)

Font.register({ family: "Mulish-Bold", src: font })

const LastPage = ({ details, activeTool }) => (
  <Page style={styles.body} wrap>
    <Header activeTool={activeTool} />
    <View style={styles.wrapper}>
      <View style={{ width: "50%" }}>
        <Text style={styles.largeText}>Invoer</Text>
        <Text style={styles.mediumText}>Gebouw</Text>
        <View style={styles.compacthediContainer}>
          <Text style={[styles.smallText, styles.width100]}>Compactheid</Text>
          <View style={[styles.setMarginLeft, { alignItems: "center" }]}>
            <Image
              src={details?.Compactheid.image}
              alt={details?.Compactheid.title}
              style={{ width: 30, height: "auto" }}
            />
            <Text style={styles.zeerComapctText}>
              {details?.Compactheid.title}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details?.Compactheid.subtitle}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            Luchtdichtheid
          </Text>
          <View style={styles.setMarginLeft}>
            <Text style={styles.smallLightBlueText}>
              {details?.Luchtdichtheid.value}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details?.Luchtdichtheid.secondaryValue}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            Glaspercentage
          </Text>
          <View style={styles.setMarginLeft}>
            <Text style={styles.smallLightBlueText}>
              {details?.Glaspercentage.value}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>Glas</Text>
          <View style={styles.setMarginLeft}>
            <Text style={styles.smallLightBlueText}>
              {details?.Glastype.value}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details?.Glastype.secondaryValue}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            Isolatie schil
          </Text>
          <View style={styles.setMarginLeft}>
            <Text style={styles.smallLightBlueText}>
              {details["Isolatie schil"]?.value}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details["Isolatie schil"]?.secondaryValue}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details["Isolatie schil"]?.tertiaryValue}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ width: "60%", marginLeft: "80px" }}>
        <Text style={styles.largeText}></Text>
        <Text style={[styles.mediumText, { marginTop: "42px" }]}>
          Installaties
        </Text>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            Ventilatievoud
          </Text>
          <View style={styles.setMarginLeft}>
            <Text style={styles.smallLightBlueText}>
              {details?.Ventilatievoud.value}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details?.Ventilatievoud.secondaryValue}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>Type LBK</Text>
          <View style={styles.setMarginLeft}>
            <Text style={styles.smallLightBlueText}>
              {details["Type LBK"]?.value}
            </Text>
            <Text style={styles.secondaryFontSize}>
              {details["Type LBK"]?.secondaryValue}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>CO₂ regeling</Text>
          <View style={styles.blueViewWithText}>
            <Text style={styles.smallTextInsideBlueView}>
              {details?.Co2regeling.text}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>Bevochtiging</Text>
          <View style={styles.blueViewWithText}>
            <Text style={styles.smallTextInsideBlueView}>
              {details?.Bevochtiging.text}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            Warmte- en koudeopwekking
          </Text>
          <View style={[styles.blueViewWithText, { width: "auto" }]}>
            <Text
              style={[
                styles.smallTextInsideBlueView,
                // {
                //   paddingLeft: "4px",
                //   paddingRight: "4px",
                // },
              ]}
            >
              {details?.Koudeopwekking.text}
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            Aanvoer-{"\n"}temperatuur CV
          </Text>
          <View style={styles.informationView}>
            <Text style={styles.smallLightBlueText}>
              {details?.["Aanvoer-temperatuur CV"].value}°C
            </Text>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>Verlichting</Text>
          <View style={styles.bevochtigingContainer}>
            <View style={styles.bevochtigingFirstView}>
              <Text style={styles.smallTextInsideBlueView}>
                {details?.Verlichting.text}
              </Text>
            </View>
            <View style={styles.bevochtigingSecondtView}>
              <Text
                style={[
                  styles.smallTextInsideBlueView,
                  {
                    paddingLeft: "4px",
                    paddingRight: "4px",
                  },
                ]}
              >
                {details?.Daglichtregeling.text}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.informationContainerWrapepr}>
          <Text style={[styles.smallText, styles.width100]}>
            PV-installatie
          </Text>
          <View style={styles.opbrengstContainer}>
            <Text style={styles.fontSmInstallatie}>
              Opbrengst (kWh) per m² GO
            </Text>
            <View style={[styles.bevochtigingFirstView, { marginLeft: "5px" }]}>
              <Text style={styles.smallTextInsideBlueView}>
                {details?.totaleOpbrengst === null
                  ? details?.NumberButtons.text
                  : details?.totaleOpbrengst}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.disclamerContainer}>
      <Text style={styles.disclamer}>Disclaimer</Text>
      <Text style={styles.disclamerText}>
        De resultaten in deze tool zijn indicatief en zijn derhalve niet
        geschikt om energieprestatieberekeningen van kantoorgebouwen te
        vervangen. Om te kunnen vaststellen of werkelijk aan de wettelijke
        kaders voor energieprestatie wordt voldaan, dient een volledige
        berekening volgens de NTA 8800 te worden uitgevoerd.
      </Text>
      {/* <Header /> */}
    </View>
    <Footer />
  </Page>
)

const styles = StyleSheet.create({
  disclamerContainer: {
    position: "absolute",
    bottom: "50",
    paddingHorizontal: 25,
  },
  disclamer: {
    marginTop: "130px",
    color: "#2E276A",
    fontSize: "12px",
    fontFamily: "Mulish-Bold",
  },
  disclamerText: {
    fontSize: "9px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  header: {
    height: "60px",
    width: "100%",
    borderBottom: "1px solid #707070",
    paddingBottom: "10px",
    fontFamily: "Mulish-Bold",
  },
  headlines: {
    fontSize: 18,
    color: "#2E276A",
    fontFamily: "Mulish-Bold",
  },
  headlineDescription: {
    fontSize: "10px",
    marginTop: "10px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "40px",
  },
  darkStripe: {
    backgroundColor: "#2E276A",
    width: "100%",
    height: "15px",
  },
  lightStripe: {
    width: "100%",
    height: "25px",
    backgroundColor: "#009FDF",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  headline: {
    color: "#2E276A",
    marginBottom: "10px",
    fontFamily: "Mulish-Bold",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
  largeText: {
    fontFamily: "Mulish-Bold",
    color: "#2E276A",
    fontSize: "18px",
  },
  mediumText: {
    fontFamily: "Mulish-Bold",
    color: "#2E276A",
    fontSize: "18px",
    marginTop: 20,
  },
  smallText: {
    fontFamily: "Mulish-Bold",
    color: "#2E276A",
    fontSize: "12px",
  },
  compacthediContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "15px",
  },
  informationCompactheidInformationView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "50px",
  },
  zeerComapctText: {
    fontFamily: "Mulish-Bold",
    color: "#2E276A",
    fontSize: "10px",
  },
  informationContainerWrapepr: {
    display: "flex",
    flexDirection: "row",
    marginTop: "25px",
  },
  informationView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "20px",
  },
  smallLightBlueText: {
    fontFamily: "Mulish-Bold",
    fontSize: "12px",
    color: "#009FDF",
  },
  blueViewWithText: {
    backgroundColor: "#009FDF",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "20px",
    display: "flex",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingBottom: "2px",
    paddingTop: "2px",
    borderRadius: "3px",
  },
  smallTextInsideBlueView: {
    fontFamily: "Mulish-Bold",
    fontSize: "10px",
    color: "white",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  setMarginLeft: {
    marginLeft: "20px",
  },
  fontSm: {
    fontSize: "10px",
  },
  secondaryFontSize: {
    fontSize: "8px",
  },
  bevochtigingContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px",
  },
  bevochtigingFirstView: {
    backgroundColor: "#009FDF",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "auto",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingBottom: "2px",
    paddingTop: "2px",
    borderRadius: "3px",
  },
  bevochtigingSecondtView: {
    backgroundColor: "#009FDF",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: "3px",
    marginLeft: "10px",
  },
  width100: {
    width: "100px",
  },
  fontSmInstallatie: {
    fontSize: "8px",
    alignSelf: "center",
    fontFamily: "Mulish-Bold",
    color: "#009FDF",
  },
  opbrengstContainer: {
    marginLeft: "20px",
    display: "flex",
    flexDirection: "row",
  },
})

export default LastPage
