// Import the functions you need from the SDKs you need
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCS9WzJs-lmhXaVgfq9H7QjNfZu1LrTLUs",
  authDomain: "klimaatconfigurator.firebaseapp.com",
  projectId: "klimaatconfigurator",
  storageBucket: "klimaatconfigurator.appspot.com",
  messagingSenderId: "1026689861330",
  appId: "1:1026689861330:web:26e621ade840d71ca30f0b",
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const firebaseAuth = firebase.auth()
const database = firebase.firestore()

export { database, firebaseAuth }
