export const airFlowHeatLoad = {
  totaalVloeroppervlak: 0, //This field is filled by Totaal vloeroppervlak option on first step.
  ventilatiehoeveelheid: 0, // This field is filled by Ventilatiehoeveelheid option on last step
  luchtdebiet: 0, //This we need to calculate heating options (wintercomfort) we get it by: totaalVloeroppervlak / ventilatiehoeveelheid

  interneWarmtelast: 0, //We need this field to calculate interneWarmtelastSum, and we are getting value from first step Interne warmtebelasting option.
  bezzeting: 0, //We need this field to calculate interneWarmtelastSum, and we are getting value from first step bezzeting option.

  interneWarmtelastSum: 0, //This one is for cooling options (zomercomfort): interneWarmtelast + bezzeting * 80 / totaalVloeroppervlak
  ventilatiehoeveelheidPerson: 0,
}
