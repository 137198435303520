import React from "react"
import ReactTooltip from "react-tooltip"

const Tooltip = () => {
  return (
    <ReactTooltip
      type="light"
      effect="solid"
      delayHide={100}
      padding={"8px"}
      className="w-80 bg-white text-black filter drop-shadow-sm"
    />
  )
}

export default Tooltip
