const INITAL_STATE = {
  isCalculationDone: false,
}

export default function toggleIsCalculationDone(state = INITAL_STATE, action) {
  switch (action.type) {
    case "TOGGLE_CALCULATOIN_DONE": {
      return {
        ...state,
        isCalculationDone: true,
      }
    }
    default: {
      return { ...state }
    }
  }
}
