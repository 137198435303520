import { useSelector } from "react-redux"
const EnergieLabelData = [
  {
    name: "A+++++",
    min: -100,
    max: 0,
    color: "#0C9D53",
  },
  {
    name: "A++++",
    min: 0.01,
    max: 40,
    color: "#01A652",
  },
  {
    name: "A+++",
    min: 40.01,
    max: 80,
    color: "#00AB4D",
  },
  {
    name: "A++",
    min: 80.01,
    max: 120,
    color: "#20B14A",
  },
  {
    name: "A+",
    min: 120.01,
    max: 160,
    color: "#4BB747",
  },
  {
    name: "A",
    min: 160.01,
    max: 180,
    color: "#71BF45",
  },
  {
    name: "B",
    min: 180.01,
    max: 200,
    color: "#8AC63E",
  },
  {
    name: "C",
    min: 200.01,
    max: 225,
    color: "#BDD630",
  },
  {
    name: "D",
    min: 225.01,
    max: 250,
    color: "#DCB327",
  },
  {
    name: "E",
    min: 250.01,
    max: 275,
    color: "#F7941D",
  },
  {
    name: "F",
    min: 275.01,
    max: 300,
    color: "#F05A2B",
  },
  {
    name: "G",
    min: 300.01,
    max: 999999,
    color: "#EE1C25",
  },
]
export const CalculateClimateResults = () => {
  const projectDetails = useSelector((state) => state.data)
  // Second page - Gebouw
  const CompactheidValues = projectDetails.Compactheid.values
  const LuchtdichtheidValues = projectDetails.Luchtdichtheid.values
  const GlaspercentageValues = projectDetails.Glaspercentage.values
  const GlastypeValues = projectDetails.Glastype.values
  const IsolatieValues = projectDetails["Isolatie schil"].values
  // Third page - Installaties
  const VentilatieveoudValues = projectDetails.Ventilatievoud.values
  const WarmteterugwinningValues = projectDetails["Type LBK"].values
  const Co2regelingValues = projectDetails.Co2regeling.values
  const BevochtigingValues = projectDetails.Bevochtiging.values
  const KoudeopwekkingValues = projectDetails.Koudeopwekking.values
  const AanvoertemperatuurCVValues =
    projectDetails["Aanvoer-temperatuur CV"].values
  const VerlichtingValues = projectDetails.Verlichting.values
  const VerlichtingOption = projectDetails.Daglichtregeling.text
  const VerlichtingEp2 =
    VerlichtingOption === "Ja"
      ? VerlichtingValues.ep2.inclDagl
      : VerlichtingValues.ep2.exclDagl
  const VerlichtingEp3 =
    VerlichtingOption === "Ja"
      ? VerlichtingValues.ep3.inclDagl
      : VerlichtingValues.ep3.exclDagl
  let NumberButtonsValues = projectDetails.NumberButtons.values

  const GebouwSectionStandard = 72
  const GebouwSectionCorrect = 0
  const GebouwSectionEp1 =
    CompactheidValues.ep1 *
    LuchtdichtheidValues.ep1 *
    GlaspercentageValues.ep1 *
    GlastypeValues.ep1 *
    IsolatieValues.ep1
  const GebouwSectionEp2 =
    CompactheidValues.ep2 *
    LuchtdichtheidValues.ep2 *
    GlaspercentageValues.ep2 *
    GlastypeValues.ep2 *
    IsolatieValues.ep2
  const GebouwSectionEp3 =
    CompactheidValues.ep3 *
    LuchtdichtheidValues.ep3 *
    GlaspercentageValues.ep3 *
    GlastypeValues.ep3 *
    IsolatieValues.ep3
  const InstallatiesSectionStandard = 69
  const InstallatiesSectionEp2 =
    VentilatieveoudValues.ep2 *
    WarmteterugwinningValues.ep2 *
    Co2regelingValues.ep2 *
    BevochtigingValues.ep2 *
    KoudeopwekkingValues.ep2 *
    AanvoertemperatuurCVValues.ep2 *
    VerlichtingEp2
  const InstallatiesSectionCorrect = 0
  const Beng1 = Math.round(
    GebouwSectionStandard * GebouwSectionEp1 + GebouwSectionCorrect
  )
  const zaDodat = projectDetails.totaleOpbrengst
  const floorArea = parseInt(projectDetails.Gebruiksoppervlakte)
  const PV2 = zaDodat / floorArea
  const Beng2 =
    zaDodat !== null && zaDodat !== NaN
      ? // if totaleOpbrengst is entered
        Math.round(
          InstallatiesSectionStandard *
            GebouwSectionEp2 *
            InstallatiesSectionEp2 +
            InstallatiesSectionCorrect -
            PV2 * 1.45
        )
      : Math.round(
          InstallatiesSectionStandard *
            GebouwSectionEp2 *
            InstallatiesSectionEp2 +
            InstallatiesSectionCorrect +
            NumberButtonsValues.ep2
        )
  const Energielabel = EnergieLabelData.find(
    (item) => Beng2 >= item.min && Beng2 <= item.max
  )
  const WKH = (
    GebouwSectionEp3 *
    VentilatieveoudValues.ep3 *
    WarmteterugwinningValues.ep3 *
    Co2regelingValues.ep3 *
    BevochtigingValues.ep3 *
    KoudeopwekkingValues.ep3 *
    VerlichtingEp3 *
    0.306
  ).toFixed(2)
  const WKH2 =
    zaDodat !== null && zaDodat !== NaN
      ? WKH * ((PV2 + Beng2) / (1 - WKH))
      : WKH * ((NumberButtonsValues.ep3 + Beng2) / (1 - WKH))
  const Beng3 =
    zaDodat !== null && zaDodat !== NaN
      ? (((PV2 + WKH2) / (Beng2 + PV2 + WKH2)) * 100).toFixed(0)
      : (
          ((NumberButtonsValues.ep3 + WKH2) /
            (Beng2 + NumberButtonsValues.ep3 + WKH2)) *
          100
        ).toFixed(0)
  return { Beng1, Beng2, Beng3, Energielabel }
}
