import { airFlowHeatLoad } from "constants/airFlowHeatLoad"

export const powerAvailable = (choice) => {
  const { interneWarmtelastSum, luchtdebiet } = airFlowHeatLoad
  switch (choice) {
    case "Klimaatplafonds":
      return {
        heating: {
          optionA: 79,
          optionB: 82,
          optionC: 86,
        },
        cooling: {
          optionA: (44 + (luchtdebiet * 8) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
          optionB: (50 + (luchtdebiet * 9) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
          optionC: (56 + (luchtdebiet * 10) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
        },
      }
    case "Plafondinductie-units":
      return {
        heating: {
          optionA: ((airFlowHeatLoad.luchtdebiet * 3 * 12) / 3).toFixed(2),
          optionB: ((airFlowHeatLoad.luchtdebiet * 3 * 12) / 3).toFixed(2),
          optionC: ((airFlowHeatLoad.luchtdebiet * 3 * 12) / 3).toFixed(2),
        },
        cooling: {
          optionA: ((luchtdebiet * 3 * 8) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
          optionB: ((luchtdebiet * 3 * 9) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
          optionC: ((luchtdebiet * 3 * 10) / 3 - interneWarmtelastSum).toFixed(
            2
          ), //take a look at this one
        },
      }
    case "VAV + naverwarmer":
      return {
        heating: {
          optionA: ((airFlowHeatLoad.luchtdebiet * 12) / 3).toFixed(2),
          optionB: ((airFlowHeatLoad.luchtdebiet * 12) / 3).toFixed(2),
          optionC: ((airFlowHeatLoad.luchtdebiet * 12) / 3).toFixed(2),
        },
        cooling: {
          optionA: ((luchtdebiet * 8) / 3 - interneWarmtelastSum).toFixed(2),
          optionB: ((luchtdebiet * 9) / 3 - interneWarmtelastSum).toFixed(2),
          optionC: ((luchtdebiet * 10) / 3 - interneWarmtelastSum).toFixed(2),
        },
      }
    case "Fancoil-units":
      return {
        heating: {
          optionA: 130,
          optionB: 130,
          optionC: 130,
        },
        cooling: {
          optionA: (86 - interneWarmtelastSum).toFixed(2),
          optionB: (97 - interneWarmtelastSum).toFixed(2),
          optionC: (108 - interneWarmtelastSum).toFixed(2),
        },
      }
    case "Vloerverwarming/-koeling":
      return {
        heating: {
          optionA: "N/A",
          optionB: "N/A",
          optionC: 86,
        },
        cooling: {
          optionA: "N/A",
          optionB: (25 + (luchtdebiet * 9) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
          optionC: (28 + (luchtdebiet * 10) / 3 - interneWarmtelastSum).toFixed(
            2
          ),
        },
      }
    case "Radiatoren + topkoeling":
      return {
        heating: {
          optionA: "N/A",
          optionB: "N/A",
          optionC: 200,
        },
        cooling: {
          optionA: "N/A",
          optionB: ((luchtdebiet * 9) / 3 - interneWarmtelastSum).toFixed(2),
          optionC: ((luchtdebiet * 10) / 3 - interneWarmtelastSum).toFixed(2),
        },
      }
    default: {
      return
    }
  }
}
