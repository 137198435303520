/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router"
import { useHistory } from "react-router-dom"

import Stepper from "components/OriginalStepper"
import LoadingSpinner from "components/LoadingSpinner"
import GreyHeadline from "components/GreyHeadline"

/* STEPS */
import RuimteStep from "components/Steps/RuimteStep"
import OrientationStep from "components/Steps/OrientationStep"
import InstalatieStep from "components/Steps/InstalatieStep"

const RoomSteps = () => {
  const history = useHistory()

  const [step, setStep] = useState(1)
  const [isLoaded, setIsLoaded] = useState(false)
  const [roomFound, setRoomFound] = useState(null)
  const [redirect, setRedirect] = useState(null)

  let index = useSelector((state) => state.activeRoom.index)
  let userEmail = useSelector((state) => state.data.Email)
  let foundRoom = useSelector((state) => {
    if (index !== null) {
      return state.rooms.rooms.filter((elem, i) => i === index)
    }
    return null
  })

  const changeStep = (s) => {
    if (s === 1) {
      setStep(s)
    }
    if (s === 2 && roomFound[0]?.stepsDone.first) {
      setStep(s)
    } else if (s === 3 && roomFound[0]?.stepsDone.second) {
      setStep(s)
    }
    history.push(`/binnenklimaat/room-step/${s}/${index}`)
  }

  useEffect(() => {
    if (userEmail === "")
      return setRedirect(<Redirect to="/energielabelcheck/projectgegevens" />)
    setRoomFound(foundRoom)
    setIsLoaded(false)
    setIsLoaded(true)
    setStep(1)
  }, [index])

  if (!isLoaded)
    return (
      <div className="h-full w-full flex items-center justify-center mt-44">
        {redirect}
        <LoadingSpinner />
      </div>
    )

  return (
    <div className="laptop:pr-40 xl:pr-96 pt-10">
      {redirect}
      <div>
        <GreyHeadline text="RUIMTES" />
        <p className="font-bold text-h3 text-darkBlue mb-10 mt-5">
          {roomFound[0].name}
        </p>
      </div>
      {/* <Stepper
        setStep={(e) => changeStep(e)}
        stepTwo={
          (roomFound !== null ? roomFound[0]?.stepsDone?.second : step >= 2) |
          (step === 2)
        }
        stepThree={
          (roomFound !== null ? roomFound[0]?.stepsDone?.third : step >= 3) |
          (step === 3)
        }
      /> */}
      <Stepper
        stepOne={
          (roomFound !== null ? roomFound[0]?.stepsDone?.first : step >= 1) |
          (step === 1)
        }
        setStep={(e) => changeStep(e)}
        stepTwo={
          (roomFound !== null ? roomFound[0]?.stepsDone?.second : step >= 2) |
          (step === 2)
        }
        stepThree={
          (roomFound !== null ? roomFound[0]?.stepsDone?.third : step >= 3) |
          (step === 3)
        }
      />
      {step === 1 ? (
        <RuimteStep setStep={setStep} room={roomFound} index={index} />
      ) : step === 2 ? (
        <OrientationStep setStep={setStep} room={roomFound} index={index} />
      ) : step === 3 ? (
        <InstalatieStep room={roomFound} index={index} />
      ) : null}
      <div className="relative">
        <p
          className="bottom-0 right-0 absolute ml-20 mb-4 text-gray text-xs"
          style={{ fontSize: "12px" }}
        >
          Deze tool is ontwikkeld door{" "}
          <a
            href="https://renor.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline-none"
          >
            Renor
          </a>{" "}
        </p>
      </div>
    </div>
  )
}

export default RoomSteps
