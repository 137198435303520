/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react"

import InfoImage from "assets/images/info.png"

const RoomBox = ({
  image,
  title,
  selected,
  onClick,
  rotate,
  onInfoClick,
  info,
  disabled,
}) => {
  return (
    <div
      className="relative w-full h-full"
      style={{ opacity: disabled && "0.5" }}
    >
      <div
        onClick={onClick}
        className={`cursor-pointer w-full h-full flex flex-col items-center p-3 box-border rounded-md ${
          selected ? "border-4 border-lightBlue" : "border-4 border-borderColor"
        }`}
      >
        <img
          src={image}
          alt="room-image"
          className="h-24 w-24"
          style={{ transform: rotate && `rotate(${rotate}deg)` }}
        />
        <p className="font-bold text-darkBlue text-12 mt-1">{title}</p>
        <div className="mt-2 h-5 w-5 border border-borderColor rounded-full flex items-center justify-center">
          {selected && <div className="bg-lightBlue h-3 w-3 rounded-full" />}
        </div>
      </div>
      {info && (
        <div className="w-full flex justify-end absolute top-5 right-5">
          <img
            src={InfoImage}
            className="cursor-pointer h-5"
            onClick={onInfoClick}
            alt="info-image"
          />
        </div>
      )}
    </div>
  )
}

export default RoomBox
