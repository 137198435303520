import React from "react"

import Modal from "react-modal"
import "./index.css"

import { IoCheckmark, IoCloseSharp } from "react-icons/io5"

const DeleteModal = ({ open, closeModal, onConfirm }) => {
  return (
    <Modal
      isOpen={open}
      contentLabel="Example Modal"
      className="bg-white z-20 border-none"
      onRequestClose={closeModal}
      overlayClassName="Overlay"
    >
      <div className="bg-white px-16 py-10 rounded-md">
        <p className="font-bold text-12 md:text-h3 text-darkBlue">
          Weet je zeker dat je dit wilt verwijderen?
        </p>
        <div className="flex items-center justify-center space-x-5 cursor-pointer mt-5">
          <div className="flex items-center justify-center bg-green py-1 px-1 md:px-3">
            <IoCheckmark className="text-white" size={22} />
            <p
              className="text-5 md:text-12 text-white font-bold ml-2"
              onClick={onConfirm}
            >
              Ja, verwijderen
            </p>
          </div>
          <div
            className="flex items-center justify-center bg-red py-1 px-3 cursor-pointer"
            onClick={closeModal}
          >
            <IoCloseSharp className="text-white" size={22} />
            <p className="text-5 md:text-12 text-white font-bold ml-2">
              Nee, behouden
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
