/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react"

import Modal from "react-modal"
import GreyHeadline from "components/GreyHeadline"

import CloseModal from "assets/images/close-modal.svg"

const InfoModal = ({ open, closeModal, text, title, image }) => {
  return (
    <Modal
      isOpen={open}
      className="bg-white z-20 rounded-lg overflow-y-auto md:overflow-hidden ring-transparent outline-none sm:w-9/12 sm:h-9/12 md:w-1/2"
      onRequestClose={closeModal}
      ariaHideApp={false}
      overlayClassName="Overlay"
    >
      <div className="h-full w-auto inline-block laptop:flex relative rounded-lg">
        <div className="flex flex-col place-items-center gap-5">
          <div className="bg-white w-full px-6 py-6">
            <div>
              <div className="flex justify-between">
                <GreyHeadline text="INFORMATIE" />
                <img
                  src={CloseModal}
                  alt="close"
                  className=" cursor-pointer mb-auto"
                  onClick={closeModal}
                />
              </div>
              <p className="font-bold text-h2 text-darkBlue">{title}</p>
              <div className="mt-2 text-h5 md:text-base overflow-y-auto max-h-96">
                {text}
              </div>
            </div>
          </div>
          {image && (
            <div className="px-5 md:px-10 pb-5 w-full flex-1 flex items-center justify-center">
              <img src={image} alt="modal-image" className="" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default InfoModal
